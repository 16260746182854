.wrapper-editor {
  display: grid;
  padding-top: 6px;
  grid-template-columns: 1452px;
  justify-content: center;
  min-height: 1000px;
}

.article-preview-message {
  margin-bottom: 15px;
  text-align: center;
  color: #2389b1;
  font-weight: bold;
  font-size: 18px;
}

.article-preview-message .highlight {
  color: #cc0066;
}

.articleEditZone-container {
  display: flex;
  justify-content: space-between;
}

.articleEdit-editor-container {
  width: 710px;
  border: 3px solid #0f3676;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.articleEdit-editor-header {
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
}

.articleEdit-editor-mainInput {
  margin: 15px;
  margin-bottom: 0px;
}

.articleEdit-editor-mainInput input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
  background-color: #ebf8ff;
  font-size: 20px;
  font-weight: bold;
  color: #0c3a5b;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Ubuntu", sans-serif;
  transition: 0.5s;
}

.articleEdit-editor-mainInput input:focus {
  border: 2px solid #2389b1;
  background-color: #c5efff;
}

.articleEdit-editor-contentContainer {
  margin: 20px;
  margin-bottom: 0px;
}

.articleEdit-editor-contentContainer label {
  color: #0f3676;
  font-weight: bold;
  font-size: 16px;
}

.articleEdit-editor-contentEditor {
  width: 100%;
  padding-top: 20px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}
.articleEdit-editor-contentEditor .warning {
  color: red;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.articleEdit-editor-insert {
  margin: auto;
  margin-bottom: 10px;
  width: 624px;
}

.articleEdit-editor-insert-title {
  background-color: #0f3676;
  width: 185px;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  font-size: 14px;
  color: white;
  padding-left: 14px;
  border-radius: 0 0 10px 10px;
}

.articleEdit-editor-insert-btnContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #ebf8ff;
  /* background-color: #ffd837; */
  border-radius: 10px 10px 10px 0;
  border: 2px solid #0f3676;
  padding-top: 6px;
}

.articleEdit-editor-insert-btn {
  cursor: pointer;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
  width: 66px;
  height: 28px;
  font-size: 14px;
  background-color: #0f3676;
  border-radius: 5px;
  color: white;
  transition: 0.5s;
}
.articleEdit-editor-insert-btn:hover {
  background-color: #2389b1;
}

/* --------------------------------------- */

.articleEdit-editor-inputContainer {
  margin: auto;
  margin-bottom: 24px;
  width: 624px;
}

.articleEdit-editor-inputContainer .warning {
  color: red;
  font-size: 14px;
}

.articleEdit-editor-mainInput .articleEdit-editor-inputContainer {
  margin: auto;
  margin-bottom: 24px;
  width: 100%;
}

.articleEdit-editor-inputTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 35px;
}

.articleEdit-editor-input-rightContainer {
  display: flex;
  justify-content: space-between;
  width: 70px;
}

.articleEdit-editor-inputTitleContainer .icon {
  margin-bottom: 5px;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #0f3676;
  transition: 0.5s;
  cursor: pointer;
}

.articleEdit-editor-inputTitleContainer .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
  border: 2px solid #0f3676;
}

.articleEdit-editor-inputContainer .title,
.articleEdit-editor-contentContainer-title {
  width: 85px;
  background-color: #0f3676;
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  color: white;
  border-radius: 10px 10px 0 0;
}

.articleEdit-editor-inputContainer textarea {
  width: 100%;
  font-size: 14px;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Ubuntu", sans-serif;
  border-radius: 0 10px 10px 10px;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  padding: 8px;
  transition: 0.5s;
  resize: none;
}

.articleEdit-editor-inputContainer textarea:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.articleEdit-editor-input-textareaContainer {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-subtitle2 {
  width: 100%;
  height: 200px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-subtitle2 textarea {
  width: 100%;
  height: 100%;
  resize: none;
}

.articleEdit-editor-input-subtitle3 {
  width: 100%;
  height: 40px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-subtitle4 {
  width: 100%;
  height: 40px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-content {
  width: 100%;
  height: 40px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-linkContainer {
  width: 100%;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
  padding-bottom: 6px;
}

.articleEdit-editor-input-link-inputContainer {
  margin: 8px;
}

.articleEdit-editor-input-link-inputContainer label {
  font-size: 14px;
}

.articleEdit-editor-input-link-inputContainer input {
  background-color: #ebf8ff;
  border: 1px solid #0f3676;
  padding: 4px;
  width: 100%;
  font-family: "Ubuntu", sans-serif, "Microsoft JhengHei", "微軟正黑體";
  font-size: 16px;
  font-weight: bold;
  color: teal;
  transition: 0.4s;
}

.articleEdit-editor-input-link-inputContainer input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

/* -------------------------------------------- */

.articleEdit-editor-imgUpload-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  min-height: 180px;
  width: 100%;
  padding: 10px 15px;
}

.articleEdit-editor-imgUpload-preview {
  position: relative;
  min-height: 180px;
  width: 260px;
  border: 2px solid #0f3676;
  border-radius: 5px;
  background-color: #ebf8ff;
  display: block;
}

.articleEdit-editor-imgUpload-preview .img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 200px;
}

.articleEdit-editor-imgUpload-controlContainer {
  min-height: 180px;
  width: 400px;
}

.articleEdit-editor-imgUpload-control-titleContainer {
  display: flex;
  justify-content: space-between;
}

.articleEdit-editor-imgUpload-control-titleContainer h2 {
  margin-top: 10px;
  margin-left: 20px;
  color: #0f3676;
}

.articleEdit-editor-imgUpload-control-titleContainer .right {
  display: flex;
  justify-content: space-between;
  width: 75px;
}

.articleEdit-editor-imgUpload-control-titleContainer .icon {
  margin-bottom: 5px;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #0f3676;
  transition: 0.5s;
  cursor: pointer;
}

.articleEdit-editor-imgUpload-control-titleContainer .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
  border: 2px solid #0f3676;
}

.articleEdit-editor-imgUpload-controlContainer .uploadBtn {
  cursor: pointer;
  padding: 4px 8px;
  /* font-weight: bold; */
  margin-left: 12px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  width: 100px;
  background-color: #0f3676;
  color: white;
  border: 2px solid #0f3676;
  border-radius: 10px;
  transition: 0.4s;
}

.articleEdit-editor-imgUpload-controlContainer .uploadBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.articleEdit-editor-imgUpload-controlContainer .uploadPath {
  margin-left: 20px;
  font-size: 16px;
  color: #2389b1;
  font-weight: bold;
  font-style: italic;
  overflow-wrap: break-word;
  height: 50px;
  font-family: "Ubuntu", "Microsoft JhengHei", "微軟正黑體", sans-serif;
}

#upload-photo {
  display: none;
}

.articleEdit-labelContainer {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #0f3676;
}

.articleEdit-label {
  cursor: pointer;
  margin-left: 15px;
  padding: 2px;
  padding-bottom: 8px;
  vertical-align: middle;
  font-family: "Ubuntu", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #00638a;
  border: 2px solid #0f3676;
  border-radius: 5px;
  background-color: #c5efff;
}

.articleEdit-label-option {
  cursor: pointer;
  border: 2px solid #0f3676;
}

/* ----------------------------------------------- */

.articleEdit-editor-input-picture-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 24px;
  width: 624px;
  height: 164px;
  padding: 10px;
  border: 2px solid #0f3676;
  border-radius: 10px;
}

.articleEdit-editor-input-picture-preview {
  position: relative;
  width: 198px;
  height: 140px;
  border: 1px solid #0f3676;
  border-radius: 5px;
  background-color: #ebf8ff;
}

.articleEdit-editor-input-picture-preview .img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 160px;
}

.articleEdit-editor-input-picture-rightContainer {
  width: 385px;
  height: 100%;
}

.articleEdit-editor-pictureTitleContainer {
  display: flex;
  justify-content: space-between;
}

.articleEdit-editor-pictureTitleContainer .title {
  color: #0f3676;
}

.articleEdit-editor-picture-iconContainer {
  display: flex;
  justify-content: space-between;
  width: 75px;
}

.articleEdit-editor-picture-iconContainer .icon {
  margin-bottom: 5px;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #0f3676;
  transition: 0.5s;
  cursor: pointer;
}

.articleEdit-editor-picture-iconContainer .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
  border: 2px solid #0f3676;
}

.articleEdit-editor-input-picture-rightContainer .uploadBtn {
  cursor: pointer;
  padding: 4px 8px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  width: 100px;
  background-color: #0f3676;
  color: white;
  border: 2px solid #0f3676;
  border-radius: 10px;
  transition: 0.4s;
}

.articleEdit-editor-input-picture-rightContainer .uploadBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.articleEdit-editor-input-picture-rightContainer .uploadPath {
  font-size: 16px;
  color: #2389b1;
  font-weight: bold;
  font-style: italic;
  overflow-wrap: break-word;
  height: 60px;
  font-family: "Ubuntu", "Microsoft JhengHei", "微軟正黑體", sans-serif;
}

.upload-picture-hidden {
  display: none;
}

/* -------------------------------------------------------- */

.articleEdit-editor-inputContainer .codeInput {
  background-color: #111;
  color: white;
}

.articleEdit-editor-inputContainer .codeInput:focus {
  background-color: #111e4a;
}

.articleEdit-editor-input-rightContainer.code {
  width: 210px;
}

.articleEdit-editor-input-rightContainer .custom-select {
  font-family: "Ubuntu";
  background-color: #0f3676;
  color: white;
  /* font-weight: bold; */
  font-size: 16px;
  border-radius: 8px;
  padding-left: 5px;
  width: 120px;
  margin-bottom: 5px;
}

.articleEdit-editor-input-rightContainer option {
  font-family: "Ubuntu";
  font-size: 16px;
  /* font-weight: bold; */
}

/* ---------------------------- */

.articleSubmitBtn {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  min-width: 150px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: 42px;
  border-radius: 10px;
  color: white;
  background-color: #0f3676;
  border: 4px solid #0f3676;
  font-size: 24px;
  font-weight: bold;
  transition: 0.4s;
}

.articleSubmitBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

/* ------------------- Preview ----------------------- */
.article-render-tagContainer {
  border-bottom: 3px solid #0f3676;
  /* margin-bottom: 10px; */
}

.editSnippetTag {
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
}
