.account-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 780px;
  color: #0f3676;
  min-height: 1100px;
}

.accountSnippetTag {
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
}

.account-edit-container {
  border: 3px solid #0f3676;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 20px;
  padding: 25px 40px;
  /* padding-bottom: 20px; */
}

.account-edit-container .error {
  color: red;
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  font-size: 14px;
  min-height: 18px;
  width: 100%;
}

.account-essential {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
}

.account-essential .avatar-container {
  width: 120px;
}

.avatar-container .label {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.account-essential .avatar {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #0f3676;
}

.account-essential .avatar img {
  width: 120px;
  height: 120px;
}
/* -------------Main Inputs-------------------- */
#upload-avatar-img {
  display: none;
}

.account-edit-container .mainInputs {
  margin-left: 35px;
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto 25px;
}

.account-edit-container .mainInputs .label {
  font-size: 18px;
  font-weight: bold;
  /* border-bottom: 1px solid #2389b1; */
}
.account-edit-container .mainInputs .inputContainer {
  width: 100%;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  /* border-bottom: 1px solid #2389b1; */
}
.account-edit-container .mainInputs .input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  width: 95%;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  padding: 3px;
  padding-left: 8px;
  transition: 0.3s;
}

.account-edit-container .mainInputs .input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

/* .account-edit-container .mainInputs .q {
  border-bottom: 1px solid #2389b1;
} */

/* --------------------Optional Inputs------------------------- */

.optionalInputs {
  width: 100%;
  display: grid;
  grid-template-columns: 95px 45px auto 24px;
  grid-gap: 12px;
}

.optionalInputs .icon,
.mainInputs .icon,
.account-edit-avatarBtnContainer .icon {
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #0f3676;
  transition: 0.5s;
  cursor: pointer;
}

.optionalInputs .icon:hover,
.mainInputs .icon:hover,
.account-edit-avatarBtnContainer .icon:hover {
  color: white;
  background-color: #2389b1;
  border: 3px solid #00638a;
}

.optionalInputs .label {
  font-size: 18px;
  font-weight: bold;
  /* border-bottom: 1px solid #2389b1; */
}

.optionalInputs .input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  width: 95%;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  padding: 3px;
  padding-left: 8px;
  transition: 0.3s;
}

.optionalInputs .input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

/* .optionalInputs .inputContainer {
  border-bottom: 1px solid #2389b1;
}

.optionalInputs .ct,
.optionalInputs .q {
  border-bottom: 1px solid #2389b1;
} */

.optionalInputs .pw {
  padding-bottom: 18px;
}

.optionalInputs .textarea {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-weight: bold;
  font-size: 16px;
  padding: 6px;
  border: 2px solid #0f3676;
  color: #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  transition: 0.3s;
  width: 95%;
}

.optionalInputs .textarea:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.optionalInputs .error {
  min-height: 18px;
  width: 100%;
}

.onOffBtn {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 5%;
}

.onOffBtn .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-height: 24px;
}

.onOffBtn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.onOffBtn .switch .roundSlider {
  position: absolute;
  cursor: pointer;
  height: 24px;
  width: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.onOffBtn .switch .roundSlider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.onOffBtn .switch input:checked + .roundSlider {
  background-color: #2389b1;
}

.onOffBtn .switch input:checked + .roundSlider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.account-edit-submitContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.account-edit-submitContainer .btn {
  cursor: pointer;
  width: 120px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: white;
  background-color: #0f3676;
  border: 3px solid #0f3676;
  border-radius: 10px;
  padding: 3px;
  transition: 0.3s;
}

.account-edit-submitContainer .btn.cancel {
  background-color: #aaa;
}

.account-edit-submitContainer .btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.account-edit-container .mainInputs.bottom {
  margin-left: 0px;
}

/* --------------------gender Radio Part------------------------- */
.radiosContainer {
  display: flex;
}

.genderRadioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-left: 60px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.genderRadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.genderRadioContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: #0f3676 solid 2px;
  background-color: #eee;
  border-radius: 50%;
  transition: 0.3s;
}

.genderRadioContainer:hover input ~ .checkmark {
  background-color: #c5efff;
}

.genderRadioContainer input:checked ~ .checkmark {
  background-color: #2389b1;
}

.genderRadioContainer .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.genderRadioContainer input:checked ~ .checkmark:after {
  display: block;
}

.genderRadioContainer .checkmark:after {
  top: 6px;
  left: 6px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}

/* --------------------Network Account Inputs------------------------- */
.network-account-inputs {
  display: grid;
  grid-template-columns: 40px 160px 45px auto 24px;
  grid-gap: 3px;
}

.network-account-inputs .networkLogo {
  height: 52px;
}

.network-account-inputs .networkLogo img {
  height: 28px;
}

.network-account-inputs .label {
  font-size: 17px;
  font-weight: bold;
}

.network-account-inputs .input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  width: 60%;
  font-size: 17px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  padding: 3px;
  padding-left: 8px;
  transition: 0.3s;
  color: #0f3676;
}

.network-account-inputs .icon {
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #0f3676;
  transition: 0.5s;
  cursor: pointer;
}

.network-account-inputs .error {
  height: 18px;
}

/* --------------------Avatar Edit Container----------------------- */

.avatar-edit-container {
  display: flex;
}

.avatar-edit-upload {
  /* text-align: center; */
  width: 25%;
  height: 200px;
  /* background-color: #ebf8ff; */
}

.avatarUploadBtn {
  display: inline-block;
  cursor: pointer;
  padding: 2px 10px;
  /* margin-top: 20px; */
  font-weight: bold;
  font-size: 17px;
  color: white;
  background-color: #0f3676;
  border-radius: 8px;
  border: 3px solid #0f3676;
  transition: 0.3s;
}

.avatarUploadBtn:hover {
  background-color: #2389b1;
}

.avatar-edit-avatarBtnContainer {
  text-align: center;
}

.avatar-edit-control-container {
  position: relative;
  height: 200px;
  width: 75%;
  border: 3px solid #0f3676;
  border-radius: 8px;
  box-sizing: content-box;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
}

.crop-container .img {
  display: block;
  margin: auto;
  width: 200px;
  margin-top: 25px;
}

/* --------------------Account Updated----------------------- */

.account-updated-container {
  margin-left: 50px;
  margin-right: 50px;
  min-height: 1100px;
}

.account-updated-container .title {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #2389b1;
  margin-bottom: 20px;
}

.account-updated-container .avatar {
  display: block;
  border-radius: 10px;
  margin-bottom: 40px;
  background-color: #0f3676;
}

.account-updated-container .content {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 15px;
  color: #0f3676;
  font-weight: bold;
}

.account-updated-container .content .titleContainer {
  display: flex;
}
.account-updated-container .content .img {
  display: block;
  width: 24px;
  margin-right: 10px;
}

.account-updated-container .space {
  min-height: 25px;
}

.account-updated-container .pwHint {
  margin-top: 25px;
  color: #2389b1;
  font-size: 18px;
  font-weight: bold;
}
