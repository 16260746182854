@import url("https://fonts.googleapis.com/css?family=Ubuntu|Roboto");

body {
  font-family: "Microsoft JhengHei", "微軟正黑體", "Ubuntu", sans-serif;
}

.wrapper {
  padding-top: 6px;
  display: grid;
  grid-template-columns: 250px 810px 380px;
  grid-gap: 6px;
  justify-content: center;
  grid-template-areas: "leftZone middleZone rightZone";
}

#mobile {
  display: none;
}

.mobile-content-container {
  padding: 30% 10%;
  text-align: center;
}

.mobile-slogan {
  font-weight: bold;
  font-size: 5vw;
  color: #2389b1;
}

@media (max-width: 1452px) {
  .wrapper {
    grid-template-columns: 250px 810px;
    grid-template-areas: "leftZone middleZone";
  }
  .header {
    width: 1066px;
  }
  #rightZone {
    display: none;
  }
}

@media (max-width: 1066px) {
  #desktop {
    display: none;
  }
  #mobile {
    display: block;
  }
}
