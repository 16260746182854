.accountManager-container {
  min-height: 1100px;
}

.accountManager-search-panel {
  display: flex;
  color: #0f3676;
  background-color: #0f3676;
}
.accountManager-search-bar {
  background-color: #0f3676;
  height: 35px;
  margin-bottom: 10px;
  text-align: right;
}
.accountManager-search-btn {
  cursor: pointer;
  display: inline-block;
  color: white;
  margin-right: 20px;
  padding: 2px 15px;
  background-color: #2389b1;
  font-weight: bold;
  font-size: 17px;
  border-radius: 6px;
  transition: 0.4s;
}
.accountManager-search-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}

.accountManager-search-cell {
  font-size: 15px;
  padding: 10px;
  color: white;
}

.accountManager-search-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  background-color: #ebf8ff;
  color: #0f3676;
  border-width: 0;
  height: 20px;
  padding: 2px;
  padding-left: 5px;
  border-radius: 3px;
}

.accountManager-search-input.id {
  width: 65px;
}
.accountManager-search-input.date {
  width: 80px;
}

.accountManager-account-container {
  border-bottom: 1px solid #c5efff;
}

.accountManager-account-container:nth-child(odd) {
  background-color: #ebf8ff;
}
.accountManager-account-container:nth-child(even) {
  background-color: white;
}

.accountManager-account-container-first-row {
  display: grid;
  grid-template-columns: 40px 90px 150px 300px 60px 160px;
  font-size: 12px;
  color: #0f3676;
  line-height: 32px;
}

.accountManager-account-container-first-row.title {
  font-size: 14px;
  font-weight: bold;
}

.accountManager-account-avatar-container {
  padding: 4px;
  width: 32px;
  height: 32px;
}
.accountManager-account-avatar {
  width: 24px;
  height: 24px;
}

/* .accountManager-account-cell {
} */

.accountManager-account-cell-last {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: bold;
}

.accountManager-account-cell-admin {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: bold;
  color: #cc0066;
}

.accountManager-account-cell-admin label {
  color: #0f3676;
  cursor: pointer;
}

.accountManager-account-cell-last label {
  cursor: pointer;
}

.accountManager-account-details-container {
  display: none;
  font-size: 12px;
  color: #0f3676;
  margin-bottom: 5px;
}
.accountManager-account-details-container.intro {
  margin-bottom: 5px;
}
.accountManager-account-details-container.active {
  display: flex;
  flex-wrap: wrap;
}
.accountManager-account-details-cell {
  margin-right: 20px;
}
.accountManager-account-extend {
  transition: 0.4s;
}
.accountManager-account-extend:hover {
  color: #2389b1;
}
.accountManager-account-slogan {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #2389b1;
}
