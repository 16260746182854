.article-render-container {
  margin-right: auto;
  margin-left: auto;
  width: 710px;
  min-height: 1100px;
  color: #0f3676;
}

.article-render-container .messageForAuthor-container {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #2389b1;
  margin-bottom: 15px;
}
.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn {
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  color: white;
  background-color: #0f3676;
  padding: 0 10px;
  border-radius: 8px;
  border: 3px solid #0f3676;
  transition: 0.3s;
}
.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn.remove {
  color: red;
  background-color: white;
  border-color: red;
}

.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn.remove:hover {
  background-color: red;
  color: white;
}

.article-render-container .messageForAuthor-container .warning {
  font-size: 15px;
  color: rgb(211, 33, 86);
}

.article-render-container .coverImg {
  width: 100%;
}

.article-render-container .coverImg img {
  width: 100%;
  background-color: #c5efff;
}

.article-render-container .title {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 32px;
  font-weight: bold;
}

.article-render-container .label {
  position: relative;
  bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 8px;
}
.article-render-container .label.mainnet {
  border-color: #0f3676;
  color: #0f3676;
}
.article-render-container .label.game {
  border-color: #20b2aa;
  color: #20b2aa;
}
.article-render-container .label.market {
  border-color: #05bcff;
  color: #05bcff;
}
.article-render-container .label.dapps {
  border-color: #19dbb6;
  color: #19dbb6;
}
.article-render-container .label.community {
  border-color: #008cff;
  color: #008cff;
}
.article-render-container .label.bp {
  border-color: #0064db;
  color: #0064db;
}
.article-render-container .label.tutorial {
  border-color: #00d7e2;
  color: #00d7e2;
}
.article-render-container .label.comprehensive {
  border-color: #ff3333;
  color: #ff3333;
}
.article-render-container .label.politics {
  border-color: #ff9d0a;
  color: #ff9d0a;
}
.article-render-container .label.economy {
  border-color: #ddbc00;
  color: #ddbc00;
}
.article-render-container .label.industry {
  border-color: #3599c7;
  color: #3599c7;
}
.article-render-container .label.archiculture {
  border-color: #0bbe0b;
  color: #0bbe0b;
}
.article-render-container .label.commerical {
  border-color: #49c1fd;
  color: #49c1fd;
}
.article-render-container .label.technology {
  border-color: #1ac59a;
  color: #1ac59a;
}
.article-render-container .label.lifestyle {
  border-color: #e2236c;
  color: #e2236c;
}
.article-render-container .label.leisure {
  border-color: #1bc4ca;
  color: #1bc4ca;
}

.articleRender-container {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.articleRender-container.subtitle1Block {
  margin-top: 28px;
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 28px;
}

.articleRender-container.subtitle2Block {
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 24px;
}

.articleRender-container.subtitle3Block {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 32px;
  font-size: 20px;
  font-weight: bold;
}

.articleRender-container.subtitle4Block {
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
}

.articleRender-container.contentBlock {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 28px;
}

.articleRender-container.pictureBlock {
  width: 100%;
}

.articleRender-container.pictureBlock img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  background-color: white;
}

.articleRender-container.linkBlock a {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  color: #20b2aa;
  font-weight: bold;
  transition: 0.4s;
}

.articleRender-container.linkBlock a:hover {
  color: #dcab1f;
}

.articleRender-container.codeBlock {
  width: 100%;
}

/* --------------------Editor Mode------------------------------- */

.articleEdit-preview-snippetContainer {
  min-height: 106px;
  max-height: 180px;
  width: 100%;
  border-top: 3px solid #c5efff;
  border-bottom: 3px solid #c5efff;
  background-color: #ebf8ff;
  margin-bottom: 60px;
}

.articleEdit-preview-snippetContainer h3 {
  min-height: 27px;
  margin-top: 5px;
  font-size: 20px;
}

.article-render-container .article-authorSnippet-top {
  display: flex;
  height: 100px;
  /* background-color: #ebf8ff; */
}

.article-authorSnippet-top .avatarContainer,
.article-authorSnippet-bottom-container .avatarContainer,
.replyInputContainer .avatarContainer {
  width: 80px;
  min-height: 80px;
}

.article-authorSnippet-top .avatarContainer img,
.article-authorSnippet-bottom-container .avatarContainer img,
.replyInputContainer .avatarContainer img {
  display: block;
  background-color: #0f3676;
  border-radius: 6px;
  margin: 4px;
  width: 72px;
  height: 72px;
}

.article-authorSnippet-top .authorInfoContainer {
  display: flex;
  width: 100%;
  padding: 14px;
  flex-direction: column;
  justify-content: space-evenly;
}

.article-authorSnippet-bottom-container {
  border-bottom: 4px solid #0f3676;
  margin-top: 30px;
}

.article-authorSnippet-bottom-container .tipsContainer {
  display: grid;
  grid-template-columns: 40px 190px 40px 190px 40px auto;
  margin-bottom: 30px;
}

.article-authorSnippet-bottom-container .title {
  font-weight: bold;
  font-size: 22px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.article-authorSnippet-bottom-container .tipsTitleContainer {
  margin-top: 20px;
  display: flex;
}

.article-authorSnippet-bottom-container .tipsContainer .chainLogo {
  width: 30px;
  height: 30px;
}

.article-authorSnippet-bottom-container .tipsContainer .account {
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
  height: 30px;
  font-weight: bold;
  font-size: 16px;
  color: #2389b1;
}

.article-authorSnippet-bottom-container .tipsTitleContainer .query {
  position: relative;
  top: 2px;
  width: 26px;
  height: 26px;
  border: 2px solid #0f3676;
  border-radius: 8px;
  color: #0f3676;
}

.tipsTitleContainer .query .icon {
  position: absolute;
  left: 3px;
  top: -2px;
  transition: 0.5s;
}
.tipsTitleContainer .query:hover {
  background-color: #0f3676;
  color: #ffd837;
}

.article-authorSnippet-bottom-container .link {
  margin-top: 30px;
  margin-bottom: 30px;
}

.article-authorSnippet-bottom-container .link a {
  font-family: 'Ubuntu', sans-serif;
  color: #20b2aa;
  font-weight: bold;
  transition: 0.4s;
}
.article-authorSnippet-bottom-container .link a:hover {
  color: #dcab1f;
}

.article-authorSnippet-top .firstRow,
.article-authorSnippet-bottom-container .firstRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: #ebf8ff; */
}

.article-authorSnippet-bottom {
  display: flex;
}

.article-authorSnippet-bottom .authorInfoContainer {
  display: flex;
  width: 100%;
  padding: 0 14px;
  flex-direction: column;
  justify-content: space-between;
}
/* .article-authorSnippet-bottom .firstRow {

} */

.authorInfoContainer .large {
  font-size: 18px;
  font-weight: bold;
}

.authorInfoContainer .small,
.replyMessageContainer .idContainer .small {
  font-size: 14px;
  color: #3c7cbd;
  /* background-color: #ebf8ff; */
}

.authorInfoContainer .small.id {
  color: #cc0066;
}

.replyMessageContainer .idContainer .id {
  color: #cc0066;
  font-family: 'ubuntu', sans-serif;
}

.authorInfoContainer .loveContainer {
  display: flex;
  justify-content: space-between;
  min-width: 75px;
  height: 34px;
  line-height: 34px;
}

.previewLocationSnippet {
  color: #80bfff;
  font-size: 40px;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
}

.previewLocation {
  width: 100%;
  height: 350px;
  color: #80bfff;
  background-color: #ebf8ff;
  border: 5px solid #c5efff;
  text-align: center;
  line-height: 350px;
  font-size: 50px;
}

.articleEdit-preview-snippetContainer .withCoverImg {
  display: flex;
  justify-content: space-between;
}

.articleEdit-preview-snippetContainer .withImgContainer {
  max-width: 200px;
  overflow: hidden;
  padding: 5px 10px 5px 0;
  margin-top: auto;
  margin-bottom: auto;
}

.articleEdit-preview-snippetContainer .withCoverImg img {
  max-height: 120px;
}

.articleEdit-preview-snippetContainer .withImgRightContainer {
  width: 490px;
}

.articleEdit-preview-snippetContainer .titleContainer {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.articleEdit-preview-snippetContainer .outlineContainer {
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding-bottom: 6px;
}

/* ---------------Article Replies--------------------------- */

.articleReplies-container {
  margin-bottom: 100px;
}

.articleReplies-container .replyTitle {
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 20px;
  color: #2389b1;
}
.articleReplies-container .replyTitle.bottom {
  color: #0f3676;
}

.articleReplies-container .replyInputContainer {
  display: flex;
}

.articleReplies-container .input {
  margin: 4px;
  width: 100%;
}

.articleReplies-container .input .textarea {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  width: 100%;
  min-height: 72px;
  padding: 6px;
  border: 2px solid #0f3676;
  border-radius: 8px;
  background-color: #ebf8ff;
  color: #0f3676;
  font-size: 15px;
  transition: 0.4s;
  resize: none;
}

.articleReplies-container .input .textarea:focus {
  border: 2px solid #2389b1;
  background-color: #c5efff;
}

.articleReplies-container .submitContainer {
  display: flex;
  justify-content: space-between;
}

.articleReplies-container .submitContainer .btn {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: #0f3676;
  padding: 1px 5px;
  border-radius: 8px;
  border: 3px solid #0f3676;
  transition: 0.4s;
}

.articleReplies-container .submitContainer .btn.cancel {
  background-color: #aaa;
  color: white;
}

.articleReplies-container .submitContainer .btn:hover,
.articleReplies-container .submitContainer .btn.cancel:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.articleReplies-container .submitContainer .error {
  color: red;
}

.replyMessageContainer {
  display: flex;
  min-height: 60px;
  margin-bottom: 10px;
}

.replyMessageContainer .avatarContainer {
  height: 60px;
  width: 60px;
}

.replyMessageContainer .avatarContainer img {
  height: 50px;
  width: 50px;
  margin: 5px;
  border-radius: 5px;
  background-color: #0f3676;
}

.replyMessageContainer .messageContainer {
  margin: 5px;
  margin-left: 10px;
  width: 100%;
}
.replyMessageContainer .message {
  font-size: 15px;
  margin-right: 14px;
  margin-bottom: 5px;
}

.replyMessageContainer .idContainer {
  display: flex;
  justify-content: space-between;
}

.replyMessageContainer .messageContainer .name {
  font-weight: bold;
  color: #2389b1;
}
.replyMessageContainer .replyBtnContainer {
  display: flex;
  /* justify-content: flex-end; */
}
.replyMessageContainer .replyBtn {
  cursor: pointer;
  margin-right: 10px;
  color: #20b2aa;
  transition: 0.4s;
  font-size: 14px;
}
.replyMessageContainer .replyBtn:hover {
  color: #dcab1f;
}

.replyMessageContainer .replyBtn.num {
  color: #666;
}

.articleReplies-container .replyMessageContainer .bottomContainer {
  display: flex;
  justify-content: space-between;
}

/* ---------------------Embedded Reply--------------------------- */

.embeddedReplyInputContainer {
  display: grid;
  grid-template-columns: 48px auto;
}

.embeddedReplyInputContainer.none {
  display: none;
}

.embeddedReplyInputContainer .avatarContainer {
  padding-top: 6px;
  width: 48px;
  height: 48px;
}

.embeddedReplyInputContainer .avatarContainer img {
  width: 40px;
  height: 40px;
}

.embeddedReplyInputContainer .inputContainer {
  margin: 8px;
  min-height: 30px;
}
.embeddedReplyInputContainer .inputContainer .nameContainer {
  display: flex;
  justify-content: space-between;
}

.embeddedReplyInputContainer .inputContainer .name {
  font-size: 14px;
  margin-bottom: 3px;
}
.embeddedReplyInputContainer .name .id {
  font-family: 'ubuntu', sans-serif;
  color: #cc0066;
}
.embeddedReplyInputContainer .nameContainer .small {
  font-size: 13px;
  color: #3c7cbd;
}
.embeddedReplyInputContainer .nameContainer .small.time {
  color: #666;
}

.embeddedReplyInputContainer .input {
  margin: 0;
}

.embeddedReplyInputContainer .input .textarea2 {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  width: 100%;
  min-height: 40px;
  border: 2px solid #0f3676;
  border-radius: 8px;
  resize: none;
  background-color: #ebf8ff;
  font-size: 15px;
  color: #0f3676;
  padding: 5px;
  transition: 0.4s;
}

.embeddedReplyInputContainer .input .textarea2:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
