@import url(https://fonts.googleapis.com/css?family=Ubuntu|Roboto);
body {
  font-family: "Microsoft JhengHei", "微軟正黑體", "Ubuntu", sans-serif;
}

.wrapper {
  padding-top: 6px;
  display: grid;
  grid-template-columns: 250px 810px 380px;
  grid-gap: 6px;
  justify-content: center;
  grid-template-areas: "leftZone middleZone rightZone";
}

#mobile {
  display: none;
}

.mobile-content-container {
  padding: 30% 10%;
  text-align: center;
}

.mobile-slogan {
  font-weight: bold;
  font-size: 5vw;
  color: #2389b1;
}

@media (max-width: 1452px) {
  .wrapper {
    grid-template-columns: 250px 810px;
    grid-template-areas: "leftZone middleZone";
  }
  .header {
    width: 1066px;
  }
  #rightZone {
    display: none;
  }
}

@media (max-width: 1066px) {
  #desktop {
    display: none;
  }
  #mobile {
    display: block;
  }
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* body {
  overflow-x: hidden;
} */

*::before, *::after {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

a {
  background-color: transparent;
}

img {
  border-style: none;
}

table, th, td {
  border-spacing: 0;
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}
/* PrismJS 1.17.1
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup+css+clike+javascript */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #ccc;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;

}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #999;
}

.token.punctuation {
	color: #ccc;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
	color: #e2777a;
}

.token.function-name {
	color: #6196cc;
}

.token.boolean,
.token.number,
.token.function {
	color: #f08d49;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
	color: #f8c555;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
	color: #cc99cd;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
	color: #7ec699;
}

.token.operator,
.token.entity,
.token.url {
	color: #67cdcc;
}

.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.token.inserted {
	color: green;
}


.loveIcon {
  cursor: pointer;
  background: url(/static/media/love_color.017bd79e.svg);
  background-repeat: no-repeat;
  height: 34px;
  width: 34px;
  display: block;
}

.loveIcon.mono {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.loveIcon.mono:hover {
  -webkit-filter: sepia(100%);
          filter: sepia(100%);
  opacity: 1;
}

/* .edit-icon {
  display: block;
  cursor: pointer;
  background: url("./icons/pencil.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: right 2px bottom 2px;
  height: 24px;
  width: 24px;
  fill: #0f3676;
  color: #0f3676;
  border-radius: 50%;
  border: 2px solid #0f3676;
  transition: 0.4s;
}

.edit-icon:hover {
  background-color: #0f3676;
  color: #ffd837;
} */

.eyeBrow {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 34px;
  background-color: #0f3676;
  z-index: 100;
}

.eyeBrow-content {
  display: flex;
  justify-content: space-between;
  width: 1452px;
}

.eyeBrow-logo {
  padding-top: 1px;
  height: 30px;
  opacity: 0.3;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.eyeBrow-logo.community {
  padding-left: 30px;
}

.eyeBrow-logo-img {
  height: 30px;
}

.eyeBrow-logo:hover {
  opacity: 1;
}

.eyeBrow-rightZone {
  display: flex;
  justify-content: space-between;
  width: 380px;
}

.eyeBrow-rightZone-price {
  width: 120px;
}
.eyeBrow-rightZone-price.up {
  color: #2ecc71;
}
.eyeBrow-rightZone-price.down {
  color: #e74c3c;
}

.eyeBrow-rightZone-price .text {
  font-family: 'ubuntu', sans-serif;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.eyeBrow-rightZone-price .text.up {
  color: #2ecc71;
}
.eyeBrow-rightZone-price .text.down {
  color: #e74c3c;
}
.eyeBrow-rightZone-price .text:hover {
  color: #ffd837;
}
.eyeBrow-rightZone-price .text .price {
  width: 110px;
}

.eyeBrow-right-logo {
  display: block;
  margin-top: 6px;
  padding-right: 6px;
  height: 22px;
}

.eyeBrow-rightZone-shortcut-label {
  display: flex;
  line-height: 32px;
  cursor: pointer;
  color: white;
  font-size: 1.05rem;
  font-weight: bold;
  opacity: 0.5;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-left: 15px;
}

.eyeBrow-rightZone-shortcut-label:hover,
.eyeBrow-rightZone-shortcut-label.active {
  opacity: 1;
}

.eyeBrow-rightZone-shortcut-text:hover,
.eyeBrow-rightZone-shortcut-text:active {
  color: white;
  opacity: 1;
}

.header {
  max-width: 1452px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  height: 72px;
}

.header-logo {
  display: block;
  margin-top: 8px;
  height: 64px;
}

.header-logo img {
  display: block;
  top: 0;
  right: 0;
  height: 64px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.header-logo .official {
  opacity: 1;
}
.header-logo .official.active {
  opacity: 1;
}
.header-logo .official.active:hover {
  opacity: 0;
}
.header-logo .official:hover {
  opacity: 1;
}
.header-logo .community {
  position: relative;
  top: -64px;
  opacity: 0;
}
.header-logo .community:hover {
  opacity: 1;
}
.header-logo .community.active {
  opacity: 1;
}
.header-logo .community.active:hover {
  opacity: 0;
}

.version-btn-container {
  margin-top: 20px;
  display: flex;
  height: 40px;
  line-height: 34px;
  font-size: 20px;
  font-weight: bold;
}

.header-official-btn {
  background-color: #aaa;
  color: white;
  width: 90px;
  text-align: center;
  border-radius: 20px 0 0 20px;
  border: 3px solid #0f3676;
  border-right: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-official-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}
.header-official-btn.active {
  background-color: #0f3676;
  color: white;
}

.header-community-btn {
  background-color: #aaa;
  color: white;
  width: 90px;
  text-align: center;
  border: 3px solid #0f3676;
  border-left: 0;
  border-radius: 0 20px 20px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-community-btn:hover {
  background-color: #99ebff;
  color: #0f3676;
}
.header-community-btn.active {
  background-color: #00638a;
  color: white;
}

.header-communityEditor {
  width: 150px;
  text-align: center;
  border: 3px solid #0f3676;
  border-radius: 20px;
  background-color: #00638a;
  color: white;
}

.header-official-officialEditor {
  cursor: pointer;
  width: 150px;
  text-align: center;
  border: 3px solid #0f3676;
  border-right: 0;
  border-radius: 20px 0 0 20px;
  background-color: #aaa;
  color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.header-official-communityEditor {
  cursor: pointer;
  width: 150px;
  text-align: center;
  border: 3px solid #0f3676;
  border-left: 0;
  border-radius: 0 20px 20px 0;
  background-color: #aaa;
  color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.header-official-officialEditor.active {
  background-color: #0f3676;
}
.header-official-communityEditor.active {
  background-color: #00638a;
}

.header .accountPanel {
  display: flex;
  justify-content: space-between;
  width: 280px;
}
.header .accountPanel-left {
  margin-top: 22px;
  display: flex;
  font-family: "Ubuntu", sans-serif, "Microsoft JhengHei", "微軟正黑體";
  font-size: 17px;
  font-weight: bold;
  color: #2389b1;
  line-height: 36px;
}
.header .accountPanel-left-avatar-container {
  margin-right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
}
.header .accountPanel-left-avatar {
  width: 36px;
  height: 36px;
  /* background-color: #0f3676; */
}
.header .accountPanel-left-account {
  color: #00638a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header .accountPanel-left-account:hover {
  color: #2389b1;
}

.header .accountPanel-right {
  display: flex;
}

.header .accountPanel-right-logout-img-container {
  cursor: pointer;
  /* filter: grayscale(40%);
  opacity: 0.9; */
  margin-top: 12px;
  margin-right: 10px;
  width: 42px;
  font-size: 14px;
  font-weight: bold;
  color: #00638a;
  padding: 6px;
  line-height: 14px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header .accountPanel-right-logout-img-container:hover {
  border-radius: 8px;
  background-color: #0f3676;
  color: white;
  /* opacity: 1;
  filter: grayscale(0%);
  color: #2389b1; */
}

.left-community-login-container {
  padding-left: 10px;
}

.left-community-login-container .error {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

.left-community-login-tag {
  display: inline-block;
  font-size: 12px;
  color: white;
  background-color: #0f3676;
  padding: 2px 10px;
  border-radius: 5px 5px 0 0;
}

.left-community-login-input {
  font-family: "ubuntu", sans-serif, "微軟正黑體", "Microsoft JhengHei";
  color: #0f3676;
  vertical-align: top;
  font-size: 12px;
  border: 2px solid #0f3676;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 196px;
  height: 24px;
  padding-left: 5px;
  margin-bottom: 5px;
  border-radius: 0 5px 5px 5px;
}
.left-community-login-input:focus {
  background-color: #c5efff;
}

.left-community-login-last-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #20b2aa;
  font-weight: bold;
  padding-right: 10px;
  margin-bottom: 5px;
}

.left-community-login-last-row.login {
  line-height: 16px;
}

.left-community-login-btn {
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.left-community-login-btn:hover {
  color: #dcab1f;
}
.left-community-login-btn.rememberMe {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.left-community-login-btn.rememberMe:hover {
  color: #dcab1f;
}

.left-community-login-btn.rememberMe input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.left-community-login-btn.rememberMe .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ebf8ff;
  border: 2px solid #20b2aa;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.left-community-login-btn.rememberMe:hover input ~ .checkmark {
  background-color: #c5efff;
  border-color: #dcab1f;
}

.left-community-login-btn.rememberMe input:checked ~ .checkmark {
  background-color: #c5efff;
}
.left-community-login-btn.rememberMe .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.left-community-login-btn.rememberMe input:checked ~ .checkmark:after {
  display: block;
}
.left-community-login-btn.rememberMe .checkmark:after {
  left: 3px;
  top: 0px;
  width: 8px;
  height: 12px;
  border: solid #0f3676;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.left-community-login-submit-btn {
  cursor: pointer;
  display: inline-block;
  color: white;
  background-color: #0f3676;
  border: 2px solid #0f3676;
  padding: 0 10px;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.left-community-login-submit-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.left-chatroom-container {
  margin: 10px;
  min-height: 470px;
}

.left-chatroom-content-container {
  overflow-y: scroll;
  border-radius: 5px;
  padding: 3px;
  height: 650px;
  background-size: 200px;
  background-color: #c5efff;
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(153, 221, 255, 0.9)),
      to(rgba(153, 255, 221, 0.8))
    ),
    url(/static/media/chatroom_bg.d980b192.png);
  background-image: linear-gradient(
      rgba(153, 221, 255, 0.9),
      rgba(153, 255, 221, 0.8)
    ),
    url(/static/media/chatroom_bg.d980b192.png);
}

#left-chatroom-scrollbar::-webkit-scrollbar-track {
  background-color: #c5efff;
}

#left-chatroom-scrollbar::-webkit-scrollbar {
  width: 12px;
}
#left-chatroom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #2389b1;
}

.left-chat-input-container {
  margin-top: 10px;
  display: flex;
}
.left-chat-input {
  font-family: "ubuntu", sans-serif, "微軟正黑體", "Microsoft JhengHei";
  color: #0f3676;
  border: 2px solid #0f3676;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 5px;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 4px;
  resize: none;
}
.left-chat-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.left-chat-input-avatar-container {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 4px;
}
.left-chat-input-avatar {
  height: 30px;
  width: 30px;
}

.left-chat-send-btn-container {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.left-chat-send-btn {
  cursor: pointer;
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 8px;
  border-radius: 4px;
  border: 2px solid #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.left-chat-send-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}

.left-message-container {
  display: flex;
}

.left-avatar-container {
  margin-top: 4px;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 3px;
}

.left-avatar-container .avatar {
  width: 25px;
  height: 25px;
}

.left-content-container {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 3px;
  background-color: white;
  max-width: 145px;
}
.left-content-container .name {
  font-size: 10px;
  color: #2389b1;
}
.left-content-container .id {
  font-size: 10px;
  color: #cc0066;
}
.left-content-container .time {
  font-size: 10px;
  color: #555;
}

.left-content-container .message {
  color: #0f3676;
  font-size: 12px;
}

.left-content-container.me {
  background-color: #e6ffcc;
  max-width: 173px;
}

.left-content-container.me .name {
  font-size: 10px;
  color: #2389b1;
}

.left-content-container.me .id {
  font-size: 10px;
  color: #cc0066;
}

.left-content-container.me .time {
  font-size: 10px;
  color: #555;
}

.left-message-container.me .message {
  color: #0f3676;
  font-size: 12px;
}

.left-message-content-container.warning {
  background-color: #ffcccc;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 3px;
}

.left-message-content-container.warning .name {
  color: #b30000;
  font-size: 10px;
}

.left-message-content-container.warning .message {
  color: #ff0000;
}

.nav1_nav-zone {
  width: 216px;
}

.nav1_nav-zone-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.nav1_nav-zone-container a:hover {
  background: #c5efff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.nav1_nav-zone-container-btnContainer {
  width: 72px;
  height: 90px;
}
.nav1_nav-zone-container-btnContainer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
}

.nav1_nav-zone-container-btnContainer-text {
  text-align: center;
  font-weight: bold;
  color: #0f3676;
  font-size: 0.95rem;
}

/* ----------------------------------- */
#leftNewsContainer .news,
.leftNoticeContainer .news {
  margin: 0 5px 30px;
}

#leftNewsContainer .news td,
.leftNoticeContainer .news td {
  border-bottom: 1px solid #c5efff;
  padding: 2px;
  font-size: 12px;
}

#leftNewsContainer .news .date,
.leftNoticeContainer .news .date {
  width: 42px;
  padding-left: 5px;
  font-weight: bold;
  color: #0f3676;
}
#leftNewsContainer .news .title,
.leftNoticeContainer .news .title {
  width: 164px;
  color: #0f3676;
}

#leftNewsContainer .news .link,
.leftNoticeContainer .news .link {
  color: #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#leftNewsContainer .news .link:hover,
.leftNoticeContainer .news .link:hover {
  color: #dcab1f;
}

#leftNewsContainer .news tr:nth-child(even),
.leftNoticeContainer .news tr:nth-child(even) {
  background-color: #ebf8ff;
}

/* ----------------------------------- */

.nav1_nav-zone-ads {
  display: block;
  margin: auto;
  padding: 8px 0;
}

.left-dynamicPanel-container {
  display: none;
  color: #0f3676;
}

.left-dynamicPanel-container.active {
  display: block;
}

.left-dynamicPanel-first-row {
  display: flex;
}

.left-dynamicPanel-avatar-container {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;
  margin: 0 10px 10px 20px;
}

.left-dynamicPanel-avatar {
  width: 40px;
  height: 40px;
}

.left-dynamicPanel-welcome-slogan {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  width: 145px;
}

.leftAccountDetails-container {
  display: flex;
  font-size: 12px;
  flex-wrap: wrap;
}

.leftAccountDetails-container.account {
  margin-top: 5px;
}

.leftAccountDetails-label {
  width: 55px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.leftAccountDetails-email {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  width: 200px;
  word-wrap: break-word;
}

.leftAccountDetails-value {
  width: 150px;
  color: #2389b1;
  word-wrap: break-word;
  margin-bottom: 5px;
}

.leftAccountDetails-title {
  margin-top: 10px;
  margin-left: 10px;
  color: #2389b1;
  font-size: 15px;
  font-weight: bold;
}

.leftAccountDetails-account {
  width: 60px;
  line-height: 20px;
  height: 20px;
  vertical-align: 20px;
  /* font-size: 12px; */
}
.leftAccountDetails-account-icon {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.leftAccountDetails-account-icon img {
  width: 20px;
  height: 20px;
}
.leftAccountDetails-account-value {
  color: #2389b1;
  width: 110px;
  line-height: 20px;
  height: 20px;
}

.left-article-snippet-container {
  min-height: 50px;
  border-bottom: 1px solid #c5efff;
}

.left-article-snippet-container:nth-child(odd) {
  background-color: #ebf8ff;
}
.left-article-snippet-container:nth-child(even) {
  background-color: white;
}

.left-article-snippet-main-container {
  display: grid;
  grid-template-columns: auto 25px;
}
.left-article-snippet-title {
  color: #0f3676;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
}

.left-article-snippet-btns-container .editIcon {
  cursor: pointer;
  color: #2389b1;
  height: 20px;
  width: 20px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.left-article-snippet-btns-container .editIcon:hover {
  background-color: #0f3676;
  border-color: #0f3676;
  color: #ffd837;
}

.left-article-snippet-btns-container .removeIcon {
  cursor: pointer;
  color: #ff3333;
  height: 20px;
  width: 20px;
  line-height: 16px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  border: 1px solid #ff3333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.left-article-snippet-btns-container .removeIcon:hover {
  background-color: #ff3333;
  color: white;
}

.left-article-snippet-info {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
  color: #3c7cbd;
  font-size: 11px;
}

.left-article-snippet-info.last {
  margin-bottom: 3px;
}

#leftZone {
  grid-area: leftZone;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}

.nav1 .leftContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 1px;
  width: 30px;
  min-height: 900px;
}
.nav1 .rightContainer {
  min-height: 1100px;
  position: absolute;
  left: 26px;
  width: 224px;
}
.nav1 .rightContainer .upperpart {
  padding: 10px 0 0 0;
  border: solid #0f3676;
  border-width: 4px 4px 0 4px;
  border-radius: 0 10px 0 0;
}

.nav1 .button {
  cursor: pointer;
  position: relative;
  width: 30px;
  flex-basis: 100px;
  background-color: #0f3676;
  border-width: 4px 0 4px 4px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 10px 0 0 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.nav1 .button:hover {
  border-color: #2389b1;
  background-color: #2389b1;
}
.nav1 .button span {
  position: absolute;
  top: 70px;
  left: -42px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 112px;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}
.nav1 .button.active {
  background-color: white;
}
.nav1 .button.active span {
  color: #0f3676;
  font-weight: bold;
}

.content {
  display: none;
}

.content.active {
  display: block;
}

.left-title {
  background-color: #0f3676;
  border-radius: 7px 7px 0 0;
  margin: 3px 20px 10px;
  padding: 1px 15px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

/* ---------------Market Part---------------------- */

.left-title.market {
  background-color: #ffd837;
  color: #0f3676;
}

.left-first-priceList-container {
  margin-bottom: 20px;
  padding: 0 5px;
}

.left-first-priceList-container .coinContainer {
  cursor: pointer;
  font-family: '微軟正黑體', 'Microsoft JhengHei';
  display: grid;
  grid-template-columns: 70px auto 25px;
  color: #222;
  font-size: 12px;
  border-bottom: 1px solid #c5efff;
  height: 20px;
  line-height: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.left-first-priceList-container .coinContainer:nth-child(odd) {
  background-color: #ebf8ff;
}
.left-first-priceList-container .coinContainer:nth-child(even) {
  background-color: white;
}
.left-first-priceList-container .coinContainer:hover {
  background-color: #ffd837;
}

.left-first-priceList-container .coinContainer.title {
  background-color: #c5efff;
  color: #0f3676;
}

.left-first-priceList-container .coinContainer .coin {
  margin-left: 20px;
}
.left-first-priceList-container .coinContainer .price {
  margin-right: 5px;
  text-align: right;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-weight: bold;
}
.left-first-priceList-container .coinContainer .price.up {
  color: #2ecc71;
}
.left-first-priceList-container .coinContainer .price.down {
  color: #e74c3c;
}

.left-priceList-container {
  padding: 0 5px 20px;
}

.left-priceList-container .coinContainer {
  cursor: pointer;
  font-family: '微軟正黑體', 'Microsoft JhengHei';
  display: grid;
  grid-template-columns: 70px 65px auto;
  color: #222;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 1px solid #c5efff;
}

.left-priceList-container .coinContainer:nth-child(odd) {
  background-color: white;
}
.left-priceList-container .coinContainer:nth-child(even) {
  background-color: #ebf8ff;
}

.left-priceList-container .coinContainer:hover {
  background-color: #ffd837;
}

.left-priceList-container .coinContainer.up {
  background-color: #ccffcc;
}
.left-priceList-container .coinContainer.down {
  background-color: #ffcccc;
}

.left-priceList-container .coinContainer .coin {
  margin-left: 8px;
  color: #0f3676;
  font-weight: bold;
}
.left-priceList-container .coinContainer .volume {
  margin-right: 8px;
  text-align: right;
}

.left-priceList-container .coinContainer.title {
  background-color: #c5efff;
  color: #0f3676;
}
.left-priceList-container .coinContainer.title .coin {
  color: #0f3676;
}

/* -----------------------Account Part-------------------- */

.left-control-panel-slogan {
  color: #2389b1;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.left-control-panel-slogan.bottom {
  color: #555;
}
.left-control-panel-slogan.myArticles {
  padding-left: 8px;
  padding-right: 8px;
}

.left-title.extend {
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.left-title.extend:hover {
  background-color: #2389b1;
}

.left-control-panel-btns-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.left-control-panel-btn-container {
  cursor: pointer;
  display: block;
  width: 55px;
  height: 60px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  color: #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.left-control-panel-btn-container:hover {
  background-color: #c5efff;
}

.left-control-panel-btn-img {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.left-account-admin-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.left-account-admin-btn {
  cursor: pointer;
  background-color: #0f3676;
  color: white;
  font-size: 12px;
  padding: 1px 3px;
  border-radius: 6px;
  border: 2px solid #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.left-account-admin-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.left-account-admin-btn.myAccount {
  background-color: #2389b1;
  border-width: 3px;
}
.left-account-admin-btn.myAccount:hover {
  background-color: #ffd837;
}

.left-account-switch-btns-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.left-account-admin-btn.switch:hover {
  color: white;
  background-color: #2389b1;
}
.left-account-admin-btn.switch.active {
  background-color: #2389b1;
}

.left-eoscity-statistic-table {
  color: #0f3676;
  font-size: 12px;
  padding: 3px;
}
.left-eoscity-statistic-row {
  display: grid;
  grid-template-columns: 90px auto;
  border-bottom: 1px solid #c5efff;
  padding: 3px 5px;
}
.left-eoscity-statistic-row:nth-child(odd) {
  background-color: white;
}
.left-eoscity-statistic-row:nth-child(even) {
  background-color: #ebf8ff;
}

.left-notValidate-container {
  padding: 0 10px;
}

/* -----------left-footer----------- */
.left-footer-container {
  background-color: #0f3676;
  width: 224px;
  border-radius: 0 0 10px 10px;
  padding: 15px;
  padding-bottom: 25px;
}

.left-footer-container .first-row {
  display: flex;
  justify-content: space-evenly;
}
.left-footer-container .first-row .shortcut-container {
  cursor: pointer;
  color: white;
  width: 28px;
  text-align: center;
  font-size: 14px;
  opacity: 0.6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.left-footer-container .first-row .shortcut-container:hover {
  opacity: 1;
}

.left-footer-container .first-row .shortcut-container.active {
  opacity: 1;
}

.left-footer-container .first-row .icon {
  display: block;
  width: 28px;
}
.left-footer-container .second-row {
  margin-top: 15px;
  height: 32px;
}

.left-footer-container .second-row .logo {
  display: block;
  margin: auto;
  height: 32px;
}

.left-footer-container .third-row {
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.left-footer-container .third-row .contact-container {
  height: 32px;
}

.left-footer-container .third-row .contact {
  display: block;
  cursor: pointer;
  width: 32px;
  opacity: 0.5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.left-footer-container .third-row .contact:hover {
  opacity: 1;
}

.middleZone-snippet {
  display: flex;
  width: 810px;
  min-height: 101px;
  max-height: 170px;
  border-bottom: 1px solid #c5efff;
}

/* ----------Prview Mode--------------------- */

.preview-snippet {
  display: flex;
  width: 710px;
  min-height: 101px;
  max-height: 170px;
  border-bottom: 1px solid #c5efff;
}

.middleZone-snippet-imgContainer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.middleZone-snippet-imgContainer .img {
  display: block;
  min-width: 100px;
  max-width: 160px;
  min-height: 80px;
  max-height: 160px;
  margin-top: auto;
  margin-bottom: auto;
}

.middleZone-snippet-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 0;
  margin-left: 15px;
  margin-right: 15px;
}

.middleZone-snippet-content-title {
  display: inline-block;
  /* padding-top: 5px; */
  padding-bottom: 5px;
  font-size: 20px;
  color: #0f3676;
  /* color: #0c3a5b; */
  font-weight: bold;
}

.middleZone-snippet-content-outline {
  font-size: 16px;
  /* color: #0f3676; */
  color: #0c3a5b;
}

.middleZone-snippet-content-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.middleZone-snippet-content-label {
  display: inline-block;
  font-size: 14px;
  color: #3c7cbd;
}

.middleZone-snippet-content-label.userId {
  color: #cc0066;
}

.middleZone-snippet-content-articleType {
  font-size: 14px;
  color: white;
  background-color: #0f3676;
  padding: 0 5px;
  border-radius: 4px;
}
.middleZone-snippet-content-articleType.mainnet {
  background-color: #0f3676;
}
.middleZone-snippet-content-articleType.game {
  background-color: #20b2aa;
}
.middleZone-snippet-content-articleType.market {
  background-color: #05bcff;
}
.middleZone-snippet-content-articleType.dapps {
  background-color: #19dbb6;
}
.middleZone-snippet-content-articleType.community {
  background-color: #008cff;
}
.middleZone-snippet-content-articleType.bp {
  background-color: #0064db;
}
.middleZone-snippet-content-articleType.tutorial {
  background-color: #00d7e2;
}
.middleZone-snippet-content-articleType.comprehensive {
  background-color: #ff3333;
}
.middleZone-snippet-content-articleType.politics {
  background-color: #ff9d0a;
}
.middleZone-snippet-content-articleType.economy {
  background-color: #ddbc00;
}
.middleZone-snippet-content-articleType.industry {
  background-color: #3599c7;
}
.middleZone-snippet-content-articleType.archiculture {
  background-color: #0bbe0b;
}
.middleZone-snippet-content-articleType.commerical {
  background-color: #49c1fd;
}
.middleZone-snippet-content-articleType.technology {
  background-color: #1ac59a;
}
.middleZone-snippet-content-articleType.lifestyle {
  background-color: #e2236c;
}
.middleZone-snippet-content-articleType.leisure {
  background-color: #1bc4ca;
}

#middleZone-home {
  grid-area: middleZone;
  min-height: 1100px;
}

.middleZone-upper {
  width: 810px;
  min-height: 514px;
  margin-bottom: 6px;
}

.middleZone-upper-bannerContainer {
  position: relative;
  margin-bottom: 4px;
  width: 810px;
  height: 378px;
}

.middleZone-upper-banner {
  position: absolute;
  z-index: 0;
  opacity: 0;
  width: 810px;
  height: 378px;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}
.middleZone-upper-banner.active {
  z-index: 1;
  opacity: 1;
}

.middleZone-upper-thumbsContainer {
  display: flex;
  justify-content: space-between;
  width: 810px;
  min-height: 132px;
}

.middleZone-upper-thumbContainer {
  width: 132px;
  height: 132px;
}

.middleZone-upper-thumbnail-imgContainer {
  width: 132px;
  height: 62px;
}

.middleZone-upper-thumbnail-img {
  display: block;
  width: 132px;
  height: 62px;
}

.middleZone-upper-thumbnail-textContainer {
  width: 132px;
  height: 64px;
  padding: 5px;
  font-size: 13px;
  color: #0f3676;
  -webkit-transition: 0.75s;
  transition: 0.75s;
}

.middleZone-upper-thumbnail-textContainer.active {
  background-color: #c5efff;
  color: #2389b1;
}

.middleZone-upper-thumbnail-highlight {
  background-color: white;
}

.middleZone-upper-thumbnail-highlight.active {
  background-color: #2389b1;
  height: 6px;
}

.middleZone-upper-edit-thumbContainer {
  width: 132px;
  background-color: #c5efff;
}

.middleZone-upper-thumbnail-edit-textarea {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  vertical-align: top;
  width: 132px;
  height: 64px;
  font-size: 13px;
  resize: none;
  border: 2px solid #0f3676;
  color: #0f3676;
  padding: 4px;
  background-color: #ebfeff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}
.middleZone-upper-thumbnail-edit-textarea:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
.middleZone-upper-thumbnail-edit-btns-container {
  display: flex;
  justify-content: space-between;
  background-color: #0f3676;
  height: 30px;
  line-height: 30px;
  color: white;
  font-size: 15px;
  padding: 0 8px;
}
.middleZone-upper-thumbnail-edit-btn-container {
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middleZone-upper-thumbnail-edit-btn-container:hover {
  color: #ffd837;
}
.middleZone-upper-thumbnail-edit-url-input-container {
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  background-color: #0f3676;
  color: white;
}
.middleZone-upper-thumbnail-url-input-label {
  font-size: 12px;
}
.middleZone-upper-thumbnail-url-input {
  margin-left: 5px;
  padding-left: 3px;
  width: 90px;
  border-width: 0;
  height: 20px;
  border-radius: 6px;
}
/* ------------------------------------------------------------------- */

.middle-bottom-articleTypeBarContainer {
  display: flex;
  flex-wrap: wrap;
  width: 810px;
}

.middle-bottom-articleTypeBtn {
  cursor: pointer;
  flex-basis: 101.25px;
  padding: 4px 0;
  background-color: #0f3676;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.middle-bottom-articleTypeBtn:hover {
  background-color: #2389b1;
}

.middle-bottom-articleTypeBtn.active {
  background-color: #2389b1;
}

.rightContainer .welcomeMessage {
  text-align: center;
  color: #0f3676;
  font-weight: bold;
}
.rightContainer .welcomeMessage span {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  color: #2389b1;
  /* font-style: italic; */
}

.subContainer .chatroom-container {
  margin: 5px;
  min-height: 470px;
}

.subContainer .chatroom-content-container {
  overflow-y: scroll;
  border-radius: 10px;
  background-color: #c5efff;
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(153, 221, 255, 0.9)),
      to(rgba(153, 255, 221, 0.8))
    ),
    url(/static/media/chatroom_bg.d980b192.png);
  background-image: linear-gradient(
      rgba(153, 221, 255, 0.9),
      rgba(153, 255, 221, 0.8)
    ),
    url(/static/media/chatroom_bg.d980b192.png);
  background-size: 325px;
  padding: 0 10px;
  height: 470px;
}

#right-chatroom-scrollbar::-webkit-scrollbar-track {
  background-color: #c5efff;
}
#right-chatroom-scrollbar::-webkit-scrollbar {
  width: 20px;
}
#right-chatroom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2389b1;
}

.chatroom-content-container .messageContainer {
  padding: 5px 0;
  display: flex;
}
.chatroom-content-container .messageContainer.me {
  justify-content: flex-end;
}

.chatroom-content-container .avatarContainer {
  width: 40px;
  height: 40px;
}
.chatroom-content-container .avatar {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.chatroom-content-container .contentContainer {
  background-color: white;
  padding: 0 8px 2px;
  margin-left: 12px;
  border-radius: 6px;
}

.chatroom-content-container .contentContainer.me {
  background-color: #e6ffcc;
}
.chatroom-content-container .contentContainer.warning {
  background-color: #ffcccc;
}
.chatroom-content-container .contentContainer.warning .name {
  color: #b30000;
}
.chatroom-content-container .contentContainer.warning .message {
  color: red;
}

.chatroom-content-container .name {
  font-weight: bold;
  color: #2389b1;
  font-size: 13px;
}

.chatroom-content-container .name .id {
  color: #cc0066;
}
.chatroom-content-container .name .time {
  color: #555;
  font-size: 12px;
}
.chatroom-content-container .message {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  color: #0f3676;
  font-size: 15px;
}

.subContainer .chatroom-input-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subContainer .chatroom-input-avatarContainer {
  height: 100%;
  margin-right: 5px;
  text-align: center;
  color: #2389b1;
  font-size: 14px;
  font-weight: bold;
}

.subContainer .chatroom-input-avatarContainer img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.chatroom-input-container .chatroom-input {
  width: 268px;
}

.chatroom-input-container .chatroom-input .input {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  font-size: 15px;
  color: #0f3676;
  border: 2px solid #0f3676;
  border-radius: 5px;
  padding: 5px;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
  resize: none;
}

.chatroom-input-container .chatroom-input .input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.chatroom-input-container .chatroom-input .sendContainer {
  display: flex;
  justify-content: space-between;
}
.chatroom-input-container .chatroom-input .send {
  cursor: pointer;
  display: inline-block;
  padding: 0 6px;
  background-color: #0f3676;
  border-radius: 5px;
  border: 3px solid #0f3676;
  color: white;
  font-weight: bold;
  font-size: 14px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.chatroom-input-container .chatroom-input .send:hover {
  background-color: #ffd837;
  color: #0f3676;
}
.chatroom-input-container .chatroom-input .hints {
  color: #2389b1;
  font-size: 14px;
}

.quickLogin-btn {
  margin-top: 2px;
  background-color: #0f3676;
  color: white;
  padding: 0px 6px;
  font-size: 16px;
  border-radius: 6px;
  border: 3px solid #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.quickLogin-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.right-control-panel-btns-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.right-control-panel-btn-container {
  cursor: pointer;
  border-radius: 6px;
  padding-top: 5px;
  width: 60px;
  height: 65px;
  text-align: center;
  font-size: 12px;
  color: #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-control-panel-btn-container:hover {
  background-color: #c5efff;
}

.right-control-panel-btn-img {
  display: block;
  margin: auto;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

.subTag {
  position: relative;
}

.subTag .subIcon {
  float: left;
  width: 64px;
  height: 55px;
  padding: 4px 4px 0 4px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 10px 10px 0 0;
  border-width: 3px 3px 0 3px;
  background-color: white;
}

.subTag .title {
  float: right;
  margin-top: 22px;
  width: 288px;
  height: 33px;
  padding-top: 3px;
  border-radius: 0 10px 0 0;
  background-color: #0f3676;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.subContainer {
  padding: 6px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 0 0 10px 10px;
  border-width: 0 3px 3px 3px;
  background-color: white;
}

#rightNewsContainer .news,
#rightNoticeContainer .news {
  border-top: 1px solid #c5efff;
}
#rightNewsContainer .news .date,
#rightNoticeContainer .news .date {
  width: 50px;
  padding-left: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #0f3676;
}
#rightNewsContainer .news .title,
#rightNoticeContainer .news .title {
  width: 269px;
  padding: 2px;
  font-size: 0.9rem;
}

#rightNoticeContainer .news .link {
  color: #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
#rightNoticeContainer .news .link:hover {
  color: #dcab1f;
}

#rightNewsContainer .news tr,
#rightNoticeContainer .news tr {
  width: 100%;
}
#rightNewsContainer .news tr:nth-child(odd),
#rightNoticeContainer .news tr:nth-child(odd) {
  background-color: #ebf8ff;
}
#rightNewsContainer .news td,
#rightNoticeContainer .news td {
  padding: 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #c5efff;
}

/* -----------Right Login------------ */

.right-login-switch-btns-container {
  display: flex;
  justify-content: space-evenly;
}

.rightLoginTag {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #2389b1;
  font-size: 18px;
  font-weight: bold;
}

.rightLoginTag.register {
  color: #2389b1;
  font-style: italic;
}

.rightLoginInput {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 300px;
}

.rightLoginInput label {
  display: inline-block;
  background-color: #0f3676;
  padding: 3px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  width: 80px;
  height: 25px;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0 0;
}

.rightLoginInput input {
  font-family: 'Ubuntu', sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  font-size: 16px;
  font-weight: bold;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  border-radius: 0 5px 5px 5px;
  padding: 5px;
  padding-left: 10px;
  color: #0f3676;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.rightLoginInput input:focus {
  background-color: #c5efff;
}

.rightLoginAssist {
  cursor: pointer;
  color: #20b2aa;
  font-size: 14px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.rightLoginAssist .link {
  vertical-align: middle;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.rightLoginAssist .link:hover {
  color: #dcab1f;
}

.rememberMeContainer {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.rememberMeContainer:hover {
  color: #dcab1f;
}

.rememberMeContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.rememberMeContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ebf8ff;
  border: 2px solid #20b2aa;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.rememberMeContainer:hover input ~ .checkmark {
  background-color: #c5efff;
  border-color: #dcab1f;
}

.rememberMeContainer input:checked ~ .checkmark {
  background-color: #c5efff;
}

.rememberMeContainer .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.rememberMeContainer input:checked ~ .checkmark:after {
  display: block;
}

.rememberMeContainer .checkmark:after {
  left: 3px;
  top: 0px;
  width: 8px;
  height: 12px;
  border: solid #0f3676;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rightLoginBtn {
  display: block;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  color: white;
  background-color: #0f3676;
  border: 3px solid #0f3676;
  border-radius: 10px;
  padding: 2px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.rightLoginBtn.active {
  background-color: #2389b1;
}

.rightLoginBtn.cancel {
  background-color: #aaa;
}

.rightLoginBtn:hover {
  background-color: #2389b1;
}

.rightLoginBtn.register {
  margin-top: 20px;
  background-color: #2389b1;
  margin-bottom: 30px;
}
.rightLoginBtn.register:hover {
  background-color: #ffd837;
}

.rightLoginInput .description {
  position: relative;
  height: 30px;
}

.rightLoginInput .icon {
  position: absolute;
  right: 0;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.rightLoginInput .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
}

.rightLoginInput .error {
  min-height: 16px;
  font-size: 12px;
  width: 100%;
  color: red;
}

/* ----------------Account Details------------------ */

.notValidate-message {
  color: #0f3676;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
}
.notValidate-message.warning {
  margin-top: 10px;
  color: red;
}
.right-confirmation-btn-container {
  text-align: center;
  margin-bottom: 10px;
}
.right-confirmation-btn {
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 10px;
  background-color: #2389b1;
  border: 3px solid #0f3676;
  border-radius: 6px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-confirmation-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}

.rightAccountDetails-container {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 63px auto;
}

.rightAccountDetails-title {
  display: flex;
  margin: 20px;
}

.rightAccountDetails-avatarContainer {
  width: 72px;
  height: 72px;
  border-radius: 6px;
  overflow: hidden;
}

.rightAccountDetails-avatar {
  width: 72px;
  height: 72px;
  background-color: #0f3676;
}

.rightAccountDetails-label {
  color: #0f3676;
  font-size: 14px;
  font-weight: bold;
  height: 22px;
  line-height: 22px;
}
.rightAccountDetail-tips {
  color: #2389b1;
  font-weight: bold;
  font-size: 17px;
  margin-left: 10px;
}

.rightAccountDetails-container.chains {
  margin-top: 5px;
  grid-template-columns: 100px auto;
  grid-gap: 5px;
}

.rightAccountDetails-container.chains .rightAccountDetails-label {
  display: flex;
}

.rightAccountDetails-label img {
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 6px;
}

.rightAccountDetails-value {
  color: #0f3676;
  font-size: 13px;
  font-weight: bold;
  min-height: 22px;
  line-height: 22px;
}

.rightAccountDetails-container.chains .rightAccountDetails-label {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
}
.rightAccountDetails-container.chains .rightAccountDetails-value {
  font-family: 'ubuntu', sans-serif;
  font-size: 15px;
  height: 28px;
  line-height: 28px;
  color: #2389b1;
}

.rightAccountTag {
  font-size: 14px;
  text-align: center;
  color: #2389b1;
  font-weight: bold;
}

.right-account-admin-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.right-account-admin-btn {
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 17px;
  border-radius: 7px;
  border: 3px solid #0f3676;
  padding: 1px 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.right-account-admin-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.right-eoscity-statistic-table {
  color: #0f3676;
  font-size: 14px;
}
.right-eoscity-statistic-row {
  display: grid;
  grid-template-columns: 100px auto;
  padding: 3px 2px;
  border-bottom: 1px solid #c5efff;
}
.right-eoscity-statistic-row:nth-child(odd) {
  background-color: white;
}
.right-eoscity-statistic-row:nth-child(even) {
  background-color: #ebf8ff;
}

#partner2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#partner2 a {
  display: block;
  padding: 10px 0;
  flex-basis: 110px;
  height: 130px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}
#partner2 a:hover {
  background-color: #c5efff;
}

.coinConfigContainer {
  height: 80px;
  padding: 8px;
}
.coinTypeContainer {
  display: grid;
  grid-template-columns: 80px 80px 80px auto;
}
.coinTypeBtnContainer {
  cursor: pointer;
  height: 70px;
  width: 80px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: #222;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-weight: bold;
}
.coinTypeBtnContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.8;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.coinTypeBtnContainer img:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  opacity: 1;
}
.coinTypeBtnContainer:hover {
  color: #2389b1;
}

.coinTypeBtnContainer.active {
  color: #2389b1;
}
.coinTypeBtnContainer.active img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  opacity: 1;
}

.coinTypeContainer .coinQuickSearch {
  color: #0f3676;
  font-size: 17px;
  font-weight: bold;
}

.coinTypeContainer .coinQuickSearch input {
  margin-top: 3px;
  width: 100px;
  border: 2px solid #0f3676;
  font-size: 16px;
  padding: 3px;
  border-radius: 5px;
  background-color: #ebf8ff;
  font-weight: bold;
  color: #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.coinTypeContainer .coinQuickSearch input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.coinQuotationContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84px;
  padding: 6px 10px 10px;
}

.coinQuotationContainer:nth-child(odd) {
  background-color: white;
  color: #0f3676;
}
.coinQuotationContainer:nth-child(even) {
  background-color: #0f3676;
  color: white;
}

.coinQuotationContainer .firstRow {
  /* font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei'; */
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 17px;
}

.coinQuotationContainer .firstRow span {
  font-size: 20px;
}

.coinQuotationContainer .price {
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.coinQuotationContainer:nth-child(odd) .price {
  color: #0f3676;
}
.coinQuotationContainer:nth-child(even) .price {
  color: white;
}
.coinQuotationContainer .price.up {
  color: #2ecc71;
}
.coinQuotationContainer .price.down {
  color: #e74c3c;
}

.coinQuotationContainer .secondRow {
  display: grid;
  grid-template-columns: 135px 135px auto;
  font-size: 14px;
}

.coinQuotationContainer .secondRow .change {
  color: #e74c3c;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}
.coinQuotationContainer .secondRow .change.up {
  color: #2ecc71;
}

.coinQuotationContainer .thirdRow {
  display: grid;
  grid-template-columns: 160px 140px auto;
  font-size: 13px;
}

.coinQuotationContainer .thirdRow .trade {
  width: 100%;
  height: 100%;
  background-color: #ffd837;
  text-align: center;
  border-radius: 3px;
  color: #0f3676;
}

/* .dynamicPanel-container {
} */

.right-dynamicPanel-title-container {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  display: flex;
  width: 100%;
}
.right-dynamicPanel-title-icon-container {
  width: 64px;
  height: 55px;
  padding: 4px 4px 0 4px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 10px 10px 0 0;
  border-width: 3px 3px 0 3px;
  background-color: white;
}
.right-dynamicPanel-title-icon-container .icon {
  width: 50px;
  border-radius: 6px;
  /* background-color: #0f3676; */
}
.right-dynamicPanel-title-container .tagContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(100% - 64px);
}
.right-dynamicPanel-title-container .tag {
  cursor: pointer;
  width: 100%;
  background-color: #0f3676;
  color: white;
  height: 33px;
  line-height: 33px;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  border-radius: 0 10px 0 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 3px solid #0f3676;
}
.right-dynamicPanel-title-container .tag:hover {
  background-color: #2389b1;
  color: white;
}

.right-dynamicPanel-content-container {
  border: 6px solid #0f3676;
  border-width: 0 0 8px;
  border-radius: 0 0 6px 6px;
}

.right-dynamicPanel-content-container.active {
  background-color: white;
  border-radius: 0 0 10px 10px;
  border: 3px solid #0f3676;
  border-top: 0;
  padding: 6px;
}
.right-dynamicPanel-content {
  display: none;
}

.right-dynamicPanel-content.active {
  display: block;
}

/* ----------------Article Edit------------------ */

.right-edit-snippet-container {
  display: flex;
  justify-content: space-between;
  min-height: 65px;
  border-bottom: 1px solid #c5efff;
}

.right-edit-snippet-container:nth-child(even) {
  background-color: #ebf8ff;
}

.right-edit-snippet-content-container {
  width: 308px;
  display: flex;
  padding: 3px;
  padding-right: 6px;
  font-weight: bold;
  font-size: 14px;
  color: #0f3676;
}

.right-edit-snippet-img-container {
  height: 65px;
  padding: 5px;
}

.right-edit-snippet-img {
  height: 55px;
}

.right-edit-snippet-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-edit-snippet-edit-container {
  width: 25px;
}

.right-edit-snippet-edit-container .edit-btn {
  margin-top: 3px;
}
.right-edit-snippet-edit-container .remove-btn {
  margin-top: 5px;
}

.right-edit-snippet-edit-container .editIcon {
  cursor: pointer;
  color: #2389b1;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-edit-snippet-edit-container .editIcon:hover {
  background-color: #0f3676;
  border-color: #0f3676;
  color: #ffd837;
}

.right-edit-snippet-edit-container .removeIcon {
  cursor: pointer;
  color: #ff3333;
  height: 24px;
  width: 24px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  border: 2px solid #ff3333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-edit-snippet-edit-container .removeIcon:hover {
  background-color: #ff3333;
  color: white;
}

.right-edit-snippet-content-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.right-edit-snippet-content-info .loves,
.right-edit-snippet-content-info .time {
  font-weight: normal;
  font-size: 12px;
  color: #3c7cbd;
}

.bottomShortcut {
  display: flex;
  justify-content: space-evenly;
  background-color: #0f3676;
  padding: 20px 0 30px;
  height: 65px;
}
.bottomShortcut .top {
  display: flex;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 20px;
  opacity: 0.5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 26px;
}
.bottomShortcut .label {
  position: relative;
  top: 3px;
  width: 60px;
}
.bottomShortcut img {
  padding-top: 5px;
  height: 28px;
}
.bottomShortcut .top:hover {
  opacity: 1;
}
.bottomShortcut .selected {
  display: flex;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 20px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 26px;
}

#contact1 {
  display: flex;
  justify-content: space-evenly;
  background-color: #0f3676;
  /* padding: 10px 0 10px; */
  /* height: 60px; */
}

#contact1 img {
  height: 40px;
}
#contact2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 0 10px;
  min-height: 100px;
  background-color: #0f3676;
  border-radius: 0 0 20px 20px;
}
#contact2 a {
  display: block;
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
  flex-basis: 45px;
  opacity: 0.5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
#contact2 a:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

#rightZone {
  grid-area: rightZone;
}

.nav2 {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}
.nav2 .topContainer {
  display: flex;
}
.nav2 .topIcon {
  cursor: pointer;
  flex-basis: 76px;
  height: 60px;
  background-color: #0f3676;
  border-style: solid;
  border-width: 4px 0 0 0;
  border-color: #0f3676;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.nav2 .topIcon:first-child {
  border-width: 4px 0 0 4px;
  border-radius: 20px 0 0 0;
}
.nav2 .topIcon:last-child {
  border-width: 4px 4px 0 0;
  border-radius: 0 20px 0 0;
}
.nav2 .topIcon:hover {
  background-color: #2389b1;
  border-color: #2389b1;
}
.nav2 .topIcon.active {
  background-color: white;
}

.nav2_topIconContainer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6px;
  width: 44px;
}

.nav2 .rightContainer {
  position: relative;
  width: 100%;
  min-height: 900px;
  border-style: solid;
  border-width: 0 4px;
  border-color: #0f3676;
}

.nav2-subZoneIcon {
  padding-top: 20px;
}

.nav2-titleIcon {
  background-color: #0f3676;
  border-radius: 10px 10px 0 0;
  margin: 0 20px 10px;
  padding: 1px 15px;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.nav2-iconsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav2-iconsContainer a {
  display: block;
  cursor: pointer;
  flex-basis: 93px;
  height: 93px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.nav2-iconsContainer a:hover {
  background-color: #c5efff;
}
/* .nav2-iconContainer {
  display: none;
}
.nav2-iconContainer.active {
  display: block;
} */
.nav2-iconContainer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 71px;
  height: 71px;
}
.nav2-iconContainer-text {
  /* width: 100%; */
  text-align: center;
  font-size: 1.05rem;
  font-weight: bold;
  color: #0f3676;
}

/* ----------------------------------------- */

.nav2-subZone {
  padding: 10px;
}

/* ---------------------Navigation------------------ */

.right-nav-addNewClassBtn-container {
  text-align: center;
}
.right-nav-addNewClassBtn {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #0f3676;
  padding: 2px 12px;
  border-radius: 12px;
  border: 3px solid #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.right-nav-addNewClassBtn:hover {
  background-color: #2389b1;
}

.right-nav-title-path-input-container {
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
  background-color: #2389b1;
  padding: 10px;
  margin: 0 10px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  border-radius: 12px;
}

.right-nav-title-path-input-container.saved {
  opacity: 1;
  background-color: #0f3676;
}

.right-nav-title-path-input-container .icon {
  position: relative;
  top: -1px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  /* vertical-align: middle; */
  /* background-color: white; */
  color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-nav-title-path-input-container .icon:hover {
  background-color: white;
  color: #2389b1;
}

.right-nav-title-input {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  height: 28px;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #0f3676;
  width: 95px;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  border-radius: 6px;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.right-nav-title-input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
.right-nav-icon-group-edit-container {
  display: flex;
  flex-wrap: wrap;
}
.right-nav-icon-edit-container-outer {
  width: 93px;
  padding-top: 10px;
  height: 155px;
}
.right-nav-icon-edit-container {
  opacity: 0.6;
  background-color: #2389b1;
  margin: auto;
  width: 88px;
  height: 145px;
  padding-top: 8px;
  border-radius: 8px;
}
.right-nav-icon-edit-container.saved.withoutContent {
  opacity: 1;
}
.right-nav-icon-edit-container.saved.withContent {
  opacity: 1;
  background-color: #0f3676;
}
.right-nav-icon-edit-img-container {
  display: block;
  margin: auto;
  border-radius: 6px;
  width: 70px;
  height: 70px;
  background-color: white;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.right-nav-icon-edit-img {
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-nav-icon-edit-img:hover {
  background-color: #2389b1;
}
.right-nav-icon-edit-input-container {
  margin-top: 6px;
  text-align: center;
}
.right-nav-icon-edit-input {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  width: 70px;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  border-radius: 4px;
  text-align: center;
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.right-nav-icon-edit-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}
.right-nav-icon-edit-modify-container {
  margin: auto;
  margin-top: 6px;
  width: 86px;
  display: flex;
  justify-content: space-evenly;
  /* background-color: white; */
  height: 24px;
}
.right-nav-icon-edit-modify-container .icon {
  cursor: pointer;
  border-radius: 50%;
  /* border: 2px solid #073676; */
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  /* background-color: #0f3676; */
  background-color: #2389b1;
  color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.right-nav-icon-edit-modify-container .icon.plus {
  line-height: 24px;
}
.right-nav-icon-edit-modify-container .icon.trash {
  background-color: #2389b1;
  color: white;
}
.upload-icon {
  display: none;
}
.right-nav-icon-edit-modify-container .icon:hover {
  background-color: #ffd837;
  color: #0f3676;
}
.right-nav-icon-edit-modify-container .icon.trash:hover {
  background-color: white;
  color: #2389b1;
}
.right-nav-icon-add-container {
  padding-top: 31px;
  width: 93px;
  height: 155px;
}
.right-nav-icon-add {
  cursor: pointer;
  -webkit-filter: saturate(80%);
          filter: saturate(80%);
  opacity: 0.8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.right-nav-icon-add:hover {
  opacity: 1;
  -webkit-filter: saturate(120%);
          filter: saturate(120%);
}

.middle-community-container {
  min-height: 1100px;
}

.middle-community-overall-cover-container {
  display: flex;
  width: 100%;
  min-height: 570px;
  margin-bottom: 8px;
}

.middle-community-cover-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  width: 620px;
}

.middle-community-cover-img-container {
  max-height: 438px;
  overflow: hidden;
}
.middle-community-cover-img-container img {
  width: 610px;
}

.middle-community-side-overall-cover-container {
  width: 190px;
}

.middle-community-cover-title {
  color: #00638a;
  margin: auto;
  margin-top: 0px;
  width: 580px;
  font-size: 30px;
  font-weight: bold;
}
.middle-community-cover-info-container {
  margin: auto;
  font-size: 16px;
  font-weight: bold;
  width: 560px;
  display: flex;
  justify-content: space-between;
  color: #3c7cbd;
}

.middle-community-small-cover-snippet-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 33%;
  padding: 5px;
}

.middle-community-small-cover-snippet-img-container {
  width: 180px;
  max-height: 120px;
  overflow: hidden;
}

.middle-community-small-cover-snippet-img-container img {
  background-color: #2389b1;
  width: 180px;
}

.middle-commnity-small-cover-snippet-title {
  color: #00638a;
  font-size: 13px;
  font-weight: bold;
  margin: auto;
  width: 180px;
}

.middle-community-small-cover-snippet-info-container {
  display: flex;
  justify-content: space-between;
  color: #3c7cbd;
  font-size: 12px;
  width: 170px;
  margin: auto;
}

.middle-community-article-type-container {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  background-color: #0c3a5b;
  height: 34px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  line-height: 34px;
}

.middle-community-article-type-container .type {
  cursor: pointer;
  width: 100%;
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.middle-community-article-type-container .type:hover {
  background-color: #2389b1;
}

.middle-community-article-type-container .type.active {
  background-color: #2389b1;
}

.article-render-container {
  margin-right: auto;
  margin-left: auto;
  width: 710px;
  min-height: 1100px;
  color: #0f3676;
}

.article-render-container .messageForAuthor-container {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #2389b1;
  margin-bottom: 15px;
}
.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn {
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  color: white;
  background-color: #0f3676;
  padding: 0 10px;
  border-radius: 8px;
  border: 3px solid #0f3676;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn.remove {
  color: red;
  background-color: white;
  border-color: red;
}

.article-render-container
  .messageForAuthor-container
  .messageForAuthor-editBtn.remove:hover {
  background-color: red;
  color: white;
}

.article-render-container .messageForAuthor-container .warning {
  font-size: 15px;
  color: rgb(211, 33, 86);
}

.article-render-container .coverImg {
  width: 100%;
}

.article-render-container .coverImg img {
  width: 100%;
  background-color: #c5efff;
}

.article-render-container .title {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 32px;
  font-weight: bold;
}

.article-render-container .label {
  position: relative;
  bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 8px;
}
.article-render-container .label.mainnet {
  border-color: #0f3676;
  color: #0f3676;
}
.article-render-container .label.game {
  border-color: #20b2aa;
  color: #20b2aa;
}
.article-render-container .label.market {
  border-color: #05bcff;
  color: #05bcff;
}
.article-render-container .label.dapps {
  border-color: #19dbb6;
  color: #19dbb6;
}
.article-render-container .label.community {
  border-color: #008cff;
  color: #008cff;
}
.article-render-container .label.bp {
  border-color: #0064db;
  color: #0064db;
}
.article-render-container .label.tutorial {
  border-color: #00d7e2;
  color: #00d7e2;
}
.article-render-container .label.comprehensive {
  border-color: #ff3333;
  color: #ff3333;
}
.article-render-container .label.politics {
  border-color: #ff9d0a;
  color: #ff9d0a;
}
.article-render-container .label.economy {
  border-color: #ddbc00;
  color: #ddbc00;
}
.article-render-container .label.industry {
  border-color: #3599c7;
  color: #3599c7;
}
.article-render-container .label.archiculture {
  border-color: #0bbe0b;
  color: #0bbe0b;
}
.article-render-container .label.commerical {
  border-color: #49c1fd;
  color: #49c1fd;
}
.article-render-container .label.technology {
  border-color: #1ac59a;
  color: #1ac59a;
}
.article-render-container .label.lifestyle {
  border-color: #e2236c;
  color: #e2236c;
}
.article-render-container .label.leisure {
  border-color: #1bc4ca;
  color: #1bc4ca;
}

.articleRender-container {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.articleRender-container.subtitle1Block {
  margin-top: 28px;
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 28px;
}

.articleRender-container.subtitle2Block {
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 24px;
}

.articleRender-container.subtitle3Block {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 32px;
  font-size: 20px;
  font-weight: bold;
}

.articleRender-container.subtitle4Block {
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
}

.articleRender-container.contentBlock {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 28px;
}

.articleRender-container.pictureBlock {
  width: 100%;
}

.articleRender-container.pictureBlock img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  background-color: white;
}

.articleRender-container.linkBlock a {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  color: #20b2aa;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleRender-container.linkBlock a:hover {
  color: #dcab1f;
}

.articleRender-container.codeBlock {
  width: 100%;
}

/* --------------------Editor Mode------------------------------- */

.articleEdit-preview-snippetContainer {
  min-height: 106px;
  max-height: 180px;
  width: 100%;
  border-top: 3px solid #c5efff;
  border-bottom: 3px solid #c5efff;
  background-color: #ebf8ff;
  margin-bottom: 60px;
}

.articleEdit-preview-snippetContainer h3 {
  min-height: 27px;
  margin-top: 5px;
  font-size: 20px;
}

.article-render-container .article-authorSnippet-top {
  display: flex;
  height: 100px;
  /* background-color: #ebf8ff; */
}

.article-authorSnippet-top .avatarContainer,
.article-authorSnippet-bottom-container .avatarContainer,
.replyInputContainer .avatarContainer {
  width: 80px;
  min-height: 80px;
}

.article-authorSnippet-top .avatarContainer img,
.article-authorSnippet-bottom-container .avatarContainer img,
.replyInputContainer .avatarContainer img {
  display: block;
  background-color: #0f3676;
  border-radius: 6px;
  margin: 4px;
  width: 72px;
  height: 72px;
}

.article-authorSnippet-top .authorInfoContainer {
  display: flex;
  width: 100%;
  padding: 14px;
  flex-direction: column;
  justify-content: space-evenly;
}

.article-authorSnippet-bottom-container {
  border-bottom: 4px solid #0f3676;
  margin-top: 30px;
}

.article-authorSnippet-bottom-container .tipsContainer {
  display: grid;
  grid-template-columns: 40px 190px 40px 190px 40px auto;
  margin-bottom: 30px;
}

.article-authorSnippet-bottom-container .title {
  font-weight: bold;
  font-size: 22px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.article-authorSnippet-bottom-container .tipsTitleContainer {
  margin-top: 20px;
  display: flex;
}

.article-authorSnippet-bottom-container .tipsContainer .chainLogo {
  width: 30px;
  height: 30px;
}

.article-authorSnippet-bottom-container .tipsContainer .account {
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
  height: 30px;
  font-weight: bold;
  font-size: 16px;
  color: #2389b1;
}

.article-authorSnippet-bottom-container .tipsTitleContainer .query {
  position: relative;
  top: 2px;
  width: 26px;
  height: 26px;
  border: 2px solid #0f3676;
  border-radius: 8px;
  color: #0f3676;
}

.tipsTitleContainer .query .icon {
  position: absolute;
  left: 3px;
  top: -2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.tipsTitleContainer .query:hover {
  background-color: #0f3676;
  color: #ffd837;
}

.article-authorSnippet-bottom-container .link {
  margin-top: 30px;
  margin-bottom: 30px;
}

.article-authorSnippet-bottom-container .link a {
  font-family: 'Ubuntu', sans-serif;
  color: #20b2aa;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.article-authorSnippet-bottom-container .link a:hover {
  color: #dcab1f;
}

.article-authorSnippet-top .firstRow,
.article-authorSnippet-bottom-container .firstRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: #ebf8ff; */
}

.article-authorSnippet-bottom {
  display: flex;
}

.article-authorSnippet-bottom .authorInfoContainer {
  display: flex;
  width: 100%;
  padding: 0 14px;
  flex-direction: column;
  justify-content: space-between;
}
/* .article-authorSnippet-bottom .firstRow {

} */

.authorInfoContainer .large {
  font-size: 18px;
  font-weight: bold;
}

.authorInfoContainer .small,
.replyMessageContainer .idContainer .small {
  font-size: 14px;
  color: #3c7cbd;
  /* background-color: #ebf8ff; */
}

.authorInfoContainer .small.id {
  color: #cc0066;
}

.replyMessageContainer .idContainer .id {
  color: #cc0066;
  font-family: 'ubuntu', sans-serif;
}

.authorInfoContainer .loveContainer {
  display: flex;
  justify-content: space-between;
  min-width: 75px;
  height: 34px;
  line-height: 34px;
}

.previewLocationSnippet {
  color: #80bfff;
  font-size: 40px;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
}

.previewLocation {
  width: 100%;
  height: 350px;
  color: #80bfff;
  background-color: #ebf8ff;
  border: 5px solid #c5efff;
  text-align: center;
  line-height: 350px;
  font-size: 50px;
}

.articleEdit-preview-snippetContainer .withCoverImg {
  display: flex;
  justify-content: space-between;
}

.articleEdit-preview-snippetContainer .withImgContainer {
  max-width: 200px;
  overflow: hidden;
  padding: 5px 10px 5px 0;
  margin-top: auto;
  margin-bottom: auto;
}

.articleEdit-preview-snippetContainer .withCoverImg img {
  max-height: 120px;
}

.articleEdit-preview-snippetContainer .withImgRightContainer {
  width: 490px;
}

.articleEdit-preview-snippetContainer .titleContainer {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.articleEdit-preview-snippetContainer .outlineContainer {
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding-bottom: 6px;
}

/* ---------------Article Replies--------------------------- */

.articleReplies-container {
  margin-bottom: 100px;
}

.articleReplies-container .replyTitle {
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 20px;
  color: #2389b1;
}
.articleReplies-container .replyTitle.bottom {
  color: #0f3676;
}

.articleReplies-container .replyInputContainer {
  display: flex;
}

.articleReplies-container .input {
  margin: 4px;
  width: 100%;
}

.articleReplies-container .input .textarea {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  width: 100%;
  min-height: 72px;
  padding: 6px;
  border: 2px solid #0f3676;
  border-radius: 8px;
  background-color: #ebf8ff;
  color: #0f3676;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  resize: none;
}

.articleReplies-container .input .textarea:focus {
  border: 2px solid #2389b1;
  background-color: #c5efff;
}

.articleReplies-container .submitContainer {
  display: flex;
  justify-content: space-between;
}

.articleReplies-container .submitContainer .btn {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: #0f3676;
  padding: 1px 5px;
  border-radius: 8px;
  border: 3px solid #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleReplies-container .submitContainer .btn.cancel {
  background-color: #aaa;
  color: white;
}

.articleReplies-container .submitContainer .btn:hover,
.articleReplies-container .submitContainer .btn.cancel:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.articleReplies-container .submitContainer .error {
  color: red;
}

.replyMessageContainer {
  display: flex;
  min-height: 60px;
  margin-bottom: 10px;
}

.replyMessageContainer .avatarContainer {
  height: 60px;
  width: 60px;
}

.replyMessageContainer .avatarContainer img {
  height: 50px;
  width: 50px;
  margin: 5px;
  border-radius: 5px;
  background-color: #0f3676;
}

.replyMessageContainer .messageContainer {
  margin: 5px;
  margin-left: 10px;
  width: 100%;
}
.replyMessageContainer .message {
  font-size: 15px;
  margin-right: 14px;
  margin-bottom: 5px;
}

.replyMessageContainer .idContainer {
  display: flex;
  justify-content: space-between;
}

.replyMessageContainer .messageContainer .name {
  font-weight: bold;
  color: #2389b1;
}
.replyMessageContainer .replyBtnContainer {
  display: flex;
  /* justify-content: flex-end; */
}
.replyMessageContainer .replyBtn {
  cursor: pointer;
  margin-right: 10px;
  color: #20b2aa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 14px;
}
.replyMessageContainer .replyBtn:hover {
  color: #dcab1f;
}

.replyMessageContainer .replyBtn.num {
  color: #666;
}

.articleReplies-container .replyMessageContainer .bottomContainer {
  display: flex;
  justify-content: space-between;
}

/* ---------------------Embedded Reply--------------------------- */

.embeddedReplyInputContainer {
  display: grid;
  grid-template-columns: 48px auto;
}

.embeddedReplyInputContainer.none {
  display: none;
}

.embeddedReplyInputContainer .avatarContainer {
  padding-top: 6px;
  width: 48px;
  height: 48px;
}

.embeddedReplyInputContainer .avatarContainer img {
  width: 40px;
  height: 40px;
}

.embeddedReplyInputContainer .inputContainer {
  margin: 8px;
  min-height: 30px;
}
.embeddedReplyInputContainer .inputContainer .nameContainer {
  display: flex;
  justify-content: space-between;
}

.embeddedReplyInputContainer .inputContainer .name {
  font-size: 14px;
  margin-bottom: 3px;
}
.embeddedReplyInputContainer .name .id {
  font-family: 'ubuntu', sans-serif;
  color: #cc0066;
}
.embeddedReplyInputContainer .nameContainer .small {
  font-size: 13px;
  color: #3c7cbd;
}
.embeddedReplyInputContainer .nameContainer .small.time {
  color: #666;
}

.embeddedReplyInputContainer .input {
  margin: 0;
}

.embeddedReplyInputContainer .input .textarea2 {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  width: 100%;
  min-height: 40px;
  border: 2px solid #0f3676;
  border-radius: 8px;
  resize: none;
  background-color: #ebf8ff;
  font-size: 15px;
  color: #0f3676;
  padding: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.embeddedReplyInputContainer .input .textarea2:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.middle-navigator-wrapper {
  margin: 0 20px;
  min-height: 1100px;
}

.middle-navigator-header-container {
  display: flex;
}

.middle-navigator-img-container {
  height: 80px;
  width: 80px;
  /* background-color: #c5efff; */
}

.middle-navigator-title-container {
  height: 80px;
  padding-left: 40px;
  line-height: 80px;
  font-size: 40px;
  font-weight: bold;
  color: #0f3676;
}

.middle-navigator-subtitle-container {
  margin-top: 30px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: #0f3676;
}

.middle-navigator-intro-container {
  white-space: pre-line;
  margin-top: 27px;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  color: #0f3676;
}

.middle-navigator-content-container {
  white-space: pre-line;
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #0f3676;
}
.middle-navigator-link-container {
  display: block;
  margin-top: 25px;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  color: #20b2aa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-link-container:hover {
  color: #dcab1f;
}

.middle-navigator-recommendSnippet-container {
  white-space: pre-line;
  display: flex;
  color: #0f3676;
  border-bottom: 2px solid #c5efff;
}

.middle-navigator-recommendSnippet-left-avatar-container {
  width: 100px;
}

.middle-navigator-recommendSnippet-left-avatar {
  display: block;
  margin: auto;
  margin-top: 20px;
  width: 60px;
  height: 60px;
}

.middle-navigator-recommendSnippet-left-avatar-label {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  width: 100px;
  padding-bottom: 15px;
}
.middle-navigator-recommendSnippet-right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 670px;
  padding: 5px;
  padding-left: 15px;
}
.middle-navigator-recommendSnippet-right-title {
  white-space: pre-line;
  margin-top: 12px;
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;
  color: #0f3676;
}
.middle-navigator-recommendSnippet-right-intro {
  white-space: pre-line;
  margin-top: 9px;
  font-size: 17px;
  line-height: 25px;
  font-weight: bold;
  color: #0f3676;
}
.middle-navigator-recommendSnippet-right-content {
  white-space: pre-line;
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #0f3676;
}
.middle-navigator-recommendSnippet-right-link {
  margin-top: 9px;
  font-size: 17px;
  line-height: 25px;
  font-weight: bold;
  color: #20b2aa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-recommendSnippet-right-link:hover {
  color: #dcab1f;
}
.middle-navigator-recommendSnippet-right-lower {
  display: flex;
  justify-content: flex-end;
}
.middle-navigator-recommendSnippet-right-contactUrlIconBar-container {
  display: flex;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon-container {
  cursor: pointer;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.65;
  height: 46px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon-container:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon {
  display: block;
  width: 40px;
  height: 33px;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon-label {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #2389b1;
}

/* ------------------------EDIT MODE------------------------------- */

.middle-navigator-title-edit-container {
  padding-left: 30px;
  padding-top: 15px;
}

.middle-navigator-title-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  padding: 10px;
  background-color: #ebf8ff;
  height: 50px;
  width: 400px;
  border: 2px solid #0f3676;
  border-radius: 10px;
  font-size: 30px;
  color: #0f3676;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-title-input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.middle-navigator-edit-insert-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.middle-navigator-edit-insert-container .title {
  width: 200px;
  height: 44px;
  border-radius: 10px 0 0 10px;
  border: 2px solid #0f3676;
  background-color: #0f3676;
  font-size: 18px;
  font-weight: bold;
  color: white;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.middle-navigator-edit-insert-container .title .arrow {
  color: #ffd837;
}
.middle-navigator-edit-insert-container .btnContainer {
  width: 570px;
  background-color: #ebf8ff;
  border-radius: 0 10px 10px 0;
  border: 2px solid #0f3676;
  height: 44px;
  display: flex;
  justify-content: space-evenly;
}

.middle-navigator-edit-insert-container .btnContainer .btn {
  cursor: pointer;
  margin-top: 6px;
  background-color: #0f3676;
  height: 28px;
  color: white;
  line-height: 28px;
  width: 80px;
  text-align: center;
  font-size: 15px;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-insert-container .btnContainer .btn:hover {
  background-color: #2389b1;
}

/* .middle-navigator-edit-paragraph-container {
  background-color: #ebf8ff;
} */

.middle-navigator-edit-paragraph-upper-container {
  display: flex;
  justify-content: space-between;
}

.middle-navigator-edit-snippet-right-icon-rightSide {
  display: flex;
  justify-content: space-between;
  width: 55px;
}

.middle-navigator-edit-snippet-right-icon-rightSide.contactUrl {
  margin-top: 4.5px;
}

.middle-navigator-edit-paragraph-tag-container {
  display: inline-block;
  height: 28px;
  border-radius: 5px 5px 0 0;
  line-height: 28px;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}

.middle-navigator-edit-paragraph-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  border-radius: 0 8px 8px 8px;
  border: 2px solid #0f3676;
  width: 100%;
  resize: none;
  background-color: #ebf8ff;
  color: #0f3676;
  margin-bottom: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.middle-navigator-edit-paragraph-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.middle-navigator-edit-paragraph-input.subtitle {
  font-size: 24px;
  font-weight: bold;
  padding: 8px;
}

.middle-navigator-edit-paragraph-input.intro {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.middle-navigator-edit-paragraph-input.contentParagraph {
  min-height: 50px;
  font-size: 16px;
  padding: 10px;
}

.middle-navigator-edit-paragraph-input.link-container {
  background-color: white;
  padding: 15px;
}
.middle-navigator-edit-paragraph-input.link-container .label {
  font-size: 16px;
  font-weight: bold;
}

.middle-navigator-edit-paragraph-input.link-container .label-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
  color: #20b2aa;
  border: 2px solid #0f3676;
  border-radius: 8px;
  padding: 3px;
  padding-left: 10px;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-paragraph-input.link-container .label-input.link {
  color: #0f3676;
  font-weight: normal;
  font-size: 16px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-paragraph-input.link-container .label-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.middle-navigator-edit-paragraph-input.snippet-container {
  background-color: white;
  display: flex;
}
.middle-navigator-edit-paragraph-input.snippet-container .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px;
}
.middle-navigator-edit-paragraph-input.snippet-container .right {
  width: 670px;
}

.middle-navigator-edit-snippet-icon-container {
  width: 100px;
  height: 80px;
  padding-top: 20px;
  margin-bottom: 8px;
}
.middle-navigator-edit-snippet-icon-uploadBtn {
  display: block;
  cursor: pointer;
  text-align: center;
  margin: auto;
  margin-bottom: 8px;
  width: 70px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background-color: #0f3676;
  color: white;
  border-radius: 8px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-icon-uploadBtn:hover {
  background-color: #2389b1;
}
.middle-navigator-edit-snippet-icon {
  display: block;
  margin: auto;
  width: 60px;
  height: 60px;
}

.middle-navigator-edit-snippet-content-container {
  padding: 10px;
}
.middle-navigator-edit-snippet-content-insertBar-container {
  display: flex;
}
.middle-navigator-edit-snippet-content-insertBar-label {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px 0 0 8px;
  background-color: #0f3676;
  color: white;
}
.middle-navigator-edit-snippet-content-insertBar {
  display: flex;
  justify-content: space-evenly;
  height: 36px;
  padding: 4px;
  width: 75%;
  border: 2px solid #0f3676;
  border-radius: 0 8px 8px 0;
  background-color: #ebf8ff;
  margin-bottom: 12px;
}

.middle-navigator-edit-snippet-content-container .arrow {
  color: #ffd837;
}

.middle-navigator-edit-snippet-content-insertBar .btn {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  background-color: #0f3676;
  color: white;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.middle-navigator-edit-snippet-content-insertBar .btn:hover {
  background-color: #2389b1;
}

.middle-navigator-edit-snippet-icon-title-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  margin: auto;
  display: block;
  border: 2px solid #0f3676;
  color: #0f3676;
  width: 80px;
  height: 28px;
  line-height: 24px;
  background-color: #ebf8ff;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-icon-title-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}
.middle-navigator-edit-snippet-content-subContainer {
  margin-bottom: 12px;
}
.middle-navigator-edit-snippet-content-label {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px 6px 0 0;
  background-color: #0f3676;
  color: white;
  font-size: 15px;
}
.middle-navigator-edit-snippet-content-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  display: block;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  width: 98%;
  border-radius: 0 6px 6px 6px;
  border: 2px solid #0f3676;
  color: #0f3676;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-content-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}
.middle-navigator-edit-snippet-content-input.title {
  font-size: 22px;
  height: 34px;
  font-weight: bold;
}
.middle-navigator-edit-snippet-content-input.intro {
  resize: none;
  font-size: 18px;
  font-weight: bold;
}
.middle-navigator-edit-snippet-content-input.content {
  resize: none;
  font-size: 16px;
}
.middle-navigator-edit-snippet-content-input.link {
  background-color: white;
}
.middle-navigator-edit-snippet-content-link-label {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  width: 40px;
  height: 28px;
  line-height: 28px;
}

.middle-navigator-edit-snippet-content-link-label-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  color: #20b2aa;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
  width: 90%;
  height: 28px;
  line-height: 24px;
  border-radius: 5px;
  border: 2px solid #0f3676;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-content-link-label-input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
.middle-navigator-edit-snippet-content-link-label-input.url {
  color: #0f3676;
  font-weight: normal;
}

.middle-navigator-edit-snippet-left-addLinkIcon-container {
  width: 33px;
  height: 52px;
  cursor: pointer;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-left-addLinkIcon-container:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.middle-navigator-edit-snippet-left-addLinkIcon-imgContainer {
  width: 33px;
  height: 33px;
}
.middle-navigator-edit-snippet-left-addLinkIcon-container .description {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #2389b1;
}
.middle-navigator-edit-snippet-content-insert-contactUrl-bar-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.middle-navigator-edit-snippet-content-insert-contactUrl-bar-left {
  display: inline-block;
  height: 47px;
  line-height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #0f3676;
  color: white;
  border-radius: 8px 0 0 8px;
}
.middle-navigator-edit-snippet-content-insert-contactUrl-bar-right {
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
  height: 47px;
  background-color: #ebf8ff;
  width: 70%;
  border: 2px solid #0f3676;
  border-radius: 0 8px 8px 0;
}
.middle-navigator-edit-snippet-right-addLinkIcon-container {
  cursor: pointer;
  width: 33px;
  height: 33px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-right-addLinkIcon-container:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-container {
  margin-right: 10px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-container .upper,
.middle-navigator-edit-snippet-right-addLinkIcon-url-container .leftside {
  display: flex;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-container .leftside {
  width: 565px;
}
.middle-navigator-edit-snippet-right-icon-container {
  position: relative;
  cursor: pointer;
  font-size: 15px;
  border: 2px solid #0f3676;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #0f3676;
  color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.middle-navigator-edit-snippet-right-icon-container.small {
  font-size: 13px;
  height: 22px;
  width: 22px;
}
.middle-navigator-edit-snippet-right-icon-container:hover {
  background-color: #ffd837;
  color: #0f3676;
  font-size: 15px;
  border: 2px solid #0f3676;
}
.middle-navigator-edit-snippet-right-icon-container .plusIcon {
  position: absolute;
  top: 3px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-icon-container .minusIcon {
  position: absolute;
  top: 3px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-icon-container .plusIcon.small {
  top: 3px;
  left: 4.5px;
}

.middle-navigator-edit-snippet-right-icon-container .trashIcon {
  position: absolute;
  top: 2px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-icon-container .trashIcon.small {
  top: 2px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-label-container {
  display: flex;
  line-height: 33px;
  font-weight: bold;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-input {
  display: inline-block;
  margin: auto;
  margin-left: 5px;
  width: 60px;
  text-align: center;
  padding-top: 2px;
  height: 24px;
  color: #2389b1;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 8px;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-label {
  width: 33px;
  height: 33px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-label-img {
  width: 33px;
  height: 33px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-input.url {
  width: 400px;
  margin-left: 5px;
  padding-left: 6px;
  padding-right: 6px;
  color: #0f3676;
  font-weight: normal;
  text-align: left;
}

.account-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 780px;
  color: #0f3676;
  min-height: 1100px;
}

.accountSnippetTag {
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
}

.account-edit-container {
  border: 3px solid #0f3676;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 20px;
  padding: 25px 40px;
  /* padding-bottom: 20px; */
}

.account-edit-container .error {
  color: red;
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  font-size: 14px;
  min-height: 18px;
  width: 100%;
}

.account-essential {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
}

.account-essential .avatar-container {
  width: 120px;
}

.avatar-container .label {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.account-essential .avatar {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #0f3676;
}

.account-essential .avatar img {
  width: 120px;
  height: 120px;
}
/* -------------Main Inputs-------------------- */
#upload-avatar-img {
  display: none;
}

.account-edit-container .mainInputs {
  margin-left: 35px;
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto 25px;
}

.account-edit-container .mainInputs .label {
  font-size: 18px;
  font-weight: bold;
  /* border-bottom: 1px solid #2389b1; */
}
.account-edit-container .mainInputs .inputContainer {
  width: 100%;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  /* border-bottom: 1px solid #2389b1; */
}
.account-edit-container .mainInputs .input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  width: 95%;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  padding: 3px;
  padding-left: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.account-edit-container .mainInputs .input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

/* .account-edit-container .mainInputs .q {
  border-bottom: 1px solid #2389b1;
} */

/* --------------------Optional Inputs------------------------- */

.optionalInputs {
  width: 100%;
  display: grid;
  grid-template-columns: 95px 45px auto 24px;
  grid-gap: 12px;
}

.optionalInputs .icon,
.mainInputs .icon,
.account-edit-avatarBtnContainer .icon {
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.optionalInputs .icon:hover,
.mainInputs .icon:hover,
.account-edit-avatarBtnContainer .icon:hover {
  color: white;
  background-color: #2389b1;
  border: 3px solid #00638a;
}

.optionalInputs .label {
  font-size: 18px;
  font-weight: bold;
  /* border-bottom: 1px solid #2389b1; */
}

.optionalInputs .input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  width: 95%;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  padding: 3px;
  padding-left: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.optionalInputs .input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

/* .optionalInputs .inputContainer {
  border-bottom: 1px solid #2389b1;
}

.optionalInputs .ct,
.optionalInputs .q {
  border-bottom: 1px solid #2389b1;
} */

.optionalInputs .pw {
  padding-bottom: 18px;
}

.optionalInputs .textarea {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-weight: bold;
  font-size: 16px;
  padding: 6px;
  border: 2px solid #0f3676;
  color: #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 95%;
}

.optionalInputs .textarea:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.optionalInputs .error {
  min-height: 18px;
  width: 100%;
}

.onOffBtn {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 5%;
}

.onOffBtn .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-height: 24px;
}

.onOffBtn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.onOffBtn .switch .roundSlider {
  position: absolute;
  cursor: pointer;
  height: 24px;
  width: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.onOffBtn .switch .roundSlider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.onOffBtn .switch input:checked + .roundSlider {
  background-color: #2389b1;
}

.onOffBtn .switch input:checked + .roundSlider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

.account-edit-submitContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.account-edit-submitContainer .btn {
  cursor: pointer;
  width: 120px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: white;
  background-color: #0f3676;
  border: 3px solid #0f3676;
  border-radius: 10px;
  padding: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.account-edit-submitContainer .btn.cancel {
  background-color: #aaa;
}

.account-edit-submitContainer .btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.account-edit-container .mainInputs.bottom {
  margin-left: 0px;
}

/* --------------------gender Radio Part------------------------- */
.radiosContainer {
  display: flex;
}

.genderRadioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-left: 60px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.genderRadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.genderRadioContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: #0f3676 solid 2px;
  background-color: #eee;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.genderRadioContainer:hover input ~ .checkmark {
  background-color: #c5efff;
}

.genderRadioContainer input:checked ~ .checkmark {
  background-color: #2389b1;
}

.genderRadioContainer .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.genderRadioContainer input:checked ~ .checkmark:after {
  display: block;
}

.genderRadioContainer .checkmark:after {
  top: 6px;
  left: 6px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}

/* --------------------Network Account Inputs------------------------- */
.network-account-inputs {
  display: grid;
  grid-template-columns: 40px 160px 45px auto 24px;
  grid-gap: 3px;
}

.network-account-inputs .networkLogo {
  height: 52px;
}

.network-account-inputs .networkLogo img {
  height: 28px;
}

.network-account-inputs .label {
  font-size: 17px;
  font-weight: bold;
}

.network-account-inputs .input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  width: 60%;
  font-size: 17px;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 6px;
  background-color: #ebf8ff;
  padding: 3px;
  padding-left: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #0f3676;
}

.network-account-inputs .icon {
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.network-account-inputs .error {
  height: 18px;
}

/* --------------------Avatar Edit Container----------------------- */

.avatar-edit-container {
  display: flex;
}

.avatar-edit-upload {
  /* text-align: center; */
  width: 25%;
  height: 200px;
  /* background-color: #ebf8ff; */
}

.avatarUploadBtn {
  display: inline-block;
  cursor: pointer;
  padding: 2px 10px;
  /* margin-top: 20px; */
  font-weight: bold;
  font-size: 17px;
  color: white;
  background-color: #0f3676;
  border-radius: 8px;
  border: 3px solid #0f3676;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.avatarUploadBtn:hover {
  background-color: #2389b1;
}

.avatar-edit-avatarBtnContainer {
  text-align: center;
}

.avatar-edit-control-container {
  position: relative;
  height: 200px;
  width: 75%;
  border: 3px solid #0f3676;
  border-radius: 8px;
  box-sizing: content-box;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
}

.crop-container .img {
  display: block;
  margin: auto;
  width: 200px;
  margin-top: 25px;
}

/* --------------------Account Updated----------------------- */

.account-updated-container {
  margin-left: 50px;
  margin-right: 50px;
  min-height: 1100px;
}

.account-updated-container .title {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #2389b1;
  margin-bottom: 20px;
}

.account-updated-container .avatar {
  display: block;
  border-radius: 10px;
  margin-bottom: 40px;
  background-color: #0f3676;
}

.account-updated-container .content {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 15px;
  color: #0f3676;
  font-weight: bold;
}

.account-updated-container .content .titleContainer {
  display: flex;
}
.account-updated-container .content .img {
  display: block;
  width: 24px;
  margin-right: 10px;
}

.account-updated-container .space {
  min-height: 25px;
}

.account-updated-container .pwHint {
  margin-top: 25px;
  color: #2389b1;
  font-size: 18px;
  font-weight: bold;
}

.articleManager-container {
  min-height: 1100px;
}

.articleManager-search-panel {
  display: grid;
  grid-template-columns: 80px 190px 80px 190px 80px 190px;
  color: white;
  background-color: #0f3676;
}

.articleManage-search-cell {
  padding: 2px;
  font-size: 15px;
  padding-left: 10px;
  padding-top: 10px;
}

.articleManager-search-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  padding: 2px;
  padding-left: 5px;
  width: 165px;
  border-width: 0;
  height: 20px;
  border-radius: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #0f3676;
  background-color: #ebf8ff;
}

.articleManager-search-input:focus {
  background-color: #c5efff;
}

.articleManager-search-input-cell {
  padding: 2px;
  padding-top: 10px;
}
.articleManager-search-bar {
  text-align: right;
  background-color: #0f3676;
}

.articleManager-search-btn {
  display: inline-block;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 20px;
  width: 60px;
  font-size: 17px;
  font-weight: bold;
  background-color: #2389b1;
  color: white;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 6px;
  border-width: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleManager-search-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}
.articleManager-list-container {
  display: grid;
  grid-template-columns: 400px 10px 400px;
}

.articleManager-list-inner-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #0f3676;
  padding: 10px 0;
}

.articleManager-list-inner-title.community {
  color: #00638a;
}

.articleManager-list-snippet-container {
  font-size: 14px;
  color: #0f3676;
  font-weight: bold;
  border-bottom: 1px solid #c5efff;
}
.articleManager-list-snippet-upper-container {
  display: flex;
}
.articleManager-list-snippet-description-container {
  width: 100%;
}

.articleManager-list-snippet-img-container {
  padding: 5px;
}

.articleManager-list-snippet-img {
  display: block;
  max-height: 50px;
  max-width: 85px;
  background-color: #c5efff;
}

.articleManager-list-snippet-container:nth-child(odd) {
  background-color: #ebf8ff;
}
.articleManager-list-snippet-container:nth-child(even) {
  background-color: white;
}

.articleManager-list-snippet-container.community:nth-child(odd) {
  background-color: white;
}
.articleManager-list-snippet-container.community:nth-child(even) {
  background-color: #ebf8ff;
}

.articleManager-list-snippet-row {
  display: flex;
  padding: 4px;
}
.articleManager-list-snippet-link {
  color: #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.articleManager-list-snippet-link:hover {
  color: #2389b1;
}

.articleManager-list-snippet-row.author {
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.articleManager-list-snippet-publish-btns-container {
  color: #0f3676;
  font-size: 13px;
  font-weight: bold;
}
.articleManager-list-snippet-publish-btns-container label {
  cursor: pointer;
}
.articleManager-list-snippet-publish-btns-container input {
  cursor: pointer;
}

.articleManager-list-snippet-last-row {
  display: flex;
  justify-content: space-between;
  color: #3c7cbd;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 5px;
}

.articleManager-list-snippet-btns-container {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 55px;
}

.articleManager-list-snippet-btns-container .editIcon {
  cursor: pointer;
  color: #2389b1;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #2389b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.articleManager-list-snippet-btns-container .editIcon:hover {
  background-color: #0f3676;
  border-color: #0f3676;
  color: #ffd837;
}

.articleManager-list-snippet-btns-container .removeIcon {
  cursor: pointer;
  color: #ff3333;
  height: 24px;
  width: 24px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  border: 2px solid #ff3333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.articleManager-list-snippet-btns-container .removeIcon:hover {
  background-color: #ff3333;
  color: white;
}

.articleManager-label {
  cursor: pointer;
  font-family: 'Microsoft JhengHei', '微軟正黑體';
  height: 20px;
  border-width: 0;
  font-size: 14px;
  font-weight: bold;
  background-color: #ebf8ff;
  color: #0f3676;
  border-radius: 4px;
}

.articleManager-list-slogan {
  text-align: center;
  color: #2389b1;
  font-size: 17px;
  font-weight: bold;
}

.accountManager-container {
  min-height: 1100px;
}

.accountManager-search-panel {
  display: flex;
  color: #0f3676;
  background-color: #0f3676;
}
.accountManager-search-bar {
  background-color: #0f3676;
  height: 35px;
  margin-bottom: 10px;
  text-align: right;
}
.accountManager-search-btn {
  cursor: pointer;
  display: inline-block;
  color: white;
  margin-right: 20px;
  padding: 2px 15px;
  background-color: #2389b1;
  font-weight: bold;
  font-size: 17px;
  border-radius: 6px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.accountManager-search-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}

.accountManager-search-cell {
  font-size: 15px;
  padding: 10px;
  color: white;
}

.accountManager-search-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  background-color: #ebf8ff;
  color: #0f3676;
  border-width: 0;
  height: 20px;
  padding: 2px;
  padding-left: 5px;
  border-radius: 3px;
}

.accountManager-search-input.id {
  width: 65px;
}
.accountManager-search-input.date {
  width: 80px;
}

.accountManager-account-container {
  border-bottom: 1px solid #c5efff;
}

.accountManager-account-container:nth-child(odd) {
  background-color: #ebf8ff;
}
.accountManager-account-container:nth-child(even) {
  background-color: white;
}

.accountManager-account-container-first-row {
  display: grid;
  grid-template-columns: 40px 90px 150px 300px 60px 160px;
  font-size: 12px;
  color: #0f3676;
  line-height: 32px;
}

.accountManager-account-container-first-row.title {
  font-size: 14px;
  font-weight: bold;
}

.accountManager-account-avatar-container {
  padding: 4px;
  width: 32px;
  height: 32px;
}
.accountManager-account-avatar {
  width: 24px;
  height: 24px;
}

/* .accountManager-account-cell {
} */

.accountManager-account-cell-last {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: bold;
}

.accountManager-account-cell-admin {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: bold;
  color: #cc0066;
}

.accountManager-account-cell-admin label {
  color: #0f3676;
  cursor: pointer;
}

.accountManager-account-cell-last label {
  cursor: pointer;
}

.accountManager-account-details-container {
  display: none;
  font-size: 12px;
  color: #0f3676;
  margin-bottom: 5px;
}
.accountManager-account-details-container.intro {
  margin-bottom: 5px;
}
.accountManager-account-details-container.active {
  display: flex;
  flex-wrap: wrap;
}
.accountManager-account-details-cell {
  margin-right: 20px;
}
.accountManager-account-extend {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.accountManager-account-extend:hover {
  color: #2389b1;
}
.accountManager-account-slogan {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #2389b1;
}

.emailConfirmation-container {
  padding: 0 40px;
  min-height: 1000px;
}

.emailConfirmation-header {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #0f3676;
}

.emailConfirmation-title-container {
  display: flex;
}

.emailConfirmation-avatar-container {
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.emailConfirmation-avatar {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: #0f3676;
}

.emailConfirmation-p-container {
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  color: #2389b1;
}

.emailConfirmation-p-container.title {
  margin-left: 20px;
  width: 650px;
  color: #0f3676;
}

.emailConfirmation-btn {
  cursor: pointer;
  color: white;
  background-color: #0f3676;
  font-size: 26px;
  font-weight: bold;
  padding: 2px 18px;
  border-radius: 10px;
  border: 3px solid #0f3676;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.emailConfirmation-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.newsEdit-news {
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
  padding: 10px;
}

.newsEdit-news-bar-container {
  display: flex;
  justify-content: space-between;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #0f3676;
  font-weight: bold;
}

.newsEdit-news-bar-left {
  display: flex;
  width: 80%;
}

.newsEdit-news-bar-container.link {
  margin-top: 10px;
}

.newsEdit-news-bar-left-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  padding-left: 6px;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #0f3676;
  border-width: 2px;
  background-color: #ebf8ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.newsEdit-news-bar-left-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.newsEdit-news-bar-right {
  cursor: pointer;
  width: 16%;
  border-radius: 8px;
  border: 3px solid #0f3676;
  background-color: #0f3676;
  color: white;
  line-height: 26px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.newsEdit-news-bar-right:hover {
  background-color: #2389b1;
}

.newsEdit-news-bar-input {
  background-color: #ebf8ff;
  border-width: 0;
  color: #0f3676;
  margin-left: 5px;
  font-size: 16px;
  margin-top: 5px;
  width: 600px;
}

.newsEdit-news-row {
  border-bottom: 1px solid #c5efff;
}
.newsEdit-news-row:nth-child(odd) {
  background-color: white;
}
.newsEdit-news-row:nth-child(even) {
  background-color: #ebf8ff;
}

.newsEdit-news-row-first {
  display: grid;
  grid-template-columns: 60px auto 60px 60px;
  font-size: 14px;
  color: #0f3676;
  padding-top: 4px;
}

/* .newsEdit-news-row-first .messageLabel {
} */

.newsEdit-news-row-first .message {
  background-color: #ebf8ff;
  border: 1px solid #0f3676;
  border-radius: 4px;
  padding-left: 3px;
  width: 520px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #0f3676;
}

.newsEdit-news-row-first .message:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.newsEdit-news-row-first .date {
  color: #0f3676;
  font-size: 14px;
}

.newsEdit-news-row-second {
  display: grid;
  grid-template-columns: auto 60px 60px;
  font-size: 14px;
  color: #0f3676;
  padding-left: 60px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.newsEdit-news-row-second .link {
  background-color: #ebf8ff;
  border: 1px solid #0f3676;
  border-radius: 4px;
  color: #20b2aa;
  padding-left: 3px;
  width: 515px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.newsEdit-news-row-second .link:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.newsEdit-ps {
  margin-top: 5px;
  color: #2389b1;
  font-size: 14px;
}

.newsEdit-radio {
  cursor: pointer;
}

.newsEdit-news-row-second .saveBtn {
  cursor: pointer;
  color: #2389b1;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.newsEdit-news-row-second .saveBtn:hover {
  color: #dcab1f;
}
.newsEdit-news-row-second .deleteBtn {
  cursor: pointer;
  color: rgb(209, 0, 0);
  opacity: 0.7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.newsEdit-news-row-second .deleteBtn:hover {
  color: red;
  opacity: 1;
}

.wrapper-editor {
  display: grid;
  padding-top: 6px;
  grid-template-columns: 1452px;
  justify-content: center;
  min-height: 1000px;
}

.article-preview-message {
  margin-bottom: 15px;
  text-align: center;
  color: #2389b1;
  font-weight: bold;
  font-size: 18px;
}

.article-preview-message .highlight {
  color: #cc0066;
}

.articleEditZone-container {
  display: flex;
  justify-content: space-between;
}

.articleEdit-editor-container {
  width: 710px;
  border: 3px solid #0f3676;
  border-radius: 0 20px 20px 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.articleEdit-editor-header {
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
}

.articleEdit-editor-mainInput {
  margin: 15px;
  margin-bottom: 0px;
}

.articleEdit-editor-mainInput input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
  background-color: #ebf8ff;
  font-size: 20px;
  font-weight: bold;
  color: #0c3a5b;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Ubuntu", sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.articleEdit-editor-mainInput input:focus {
  border: 2px solid #2389b1;
  background-color: #c5efff;
}

.articleEdit-editor-contentContainer {
  margin: 20px;
  margin-bottom: 0px;
}

.articleEdit-editor-contentContainer label {
  color: #0f3676;
  font-weight: bold;
  font-size: 16px;
}

.articleEdit-editor-contentEditor {
  width: 100%;
  padding-top: 20px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}
.articleEdit-editor-contentEditor .warning {
  color: red;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.articleEdit-editor-insert {
  margin: auto;
  margin-bottom: 10px;
  width: 624px;
}

.articleEdit-editor-insert-title {
  background-color: #0f3676;
  width: 185px;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  font-size: 14px;
  color: white;
  padding-left: 14px;
  border-radius: 0 0 10px 10px;
}

.articleEdit-editor-insert-btnContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #ebf8ff;
  /* background-color: #ffd837; */
  border-radius: 10px 10px 10px 0;
  border: 2px solid #0f3676;
  padding-top: 6px;
}

.articleEdit-editor-insert-btn {
  cursor: pointer;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
  width: 66px;
  height: 28px;
  font-size: 14px;
  background-color: #0f3676;
  border-radius: 5px;
  color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.articleEdit-editor-insert-btn:hover {
  background-color: #2389b1;
}

/* --------------------------------------- */

.articleEdit-editor-inputContainer {
  margin: auto;
  margin-bottom: 24px;
  width: 624px;
}

.articleEdit-editor-inputContainer .warning {
  color: red;
  font-size: 14px;
}

.articleEdit-editor-mainInput .articleEdit-editor-inputContainer {
  margin: auto;
  margin-bottom: 24px;
  width: 100%;
}

.articleEdit-editor-inputTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 35px;
}

.articleEdit-editor-input-rightContainer {
  display: flex;
  justify-content: space-between;
  width: 70px;
}

.articleEdit-editor-inputTitleContainer .icon {
  margin-bottom: 5px;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.articleEdit-editor-inputTitleContainer .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
  border: 2px solid #0f3676;
}

.articleEdit-editor-inputContainer .title,
.articleEdit-editor-contentContainer-title {
  width: 85px;
  background-color: #0f3676;
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  color: white;
  border-radius: 10px 10px 0 0;
}

.articleEdit-editor-inputContainer textarea {
  width: 100%;
  font-size: 14px;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Ubuntu", sans-serif;
  border-radius: 0 10px 10px 10px;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  padding: 8px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  resize: none;
}

.articleEdit-editor-inputContainer textarea:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.articleEdit-editor-input-textareaContainer {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-subtitle2 {
  width: 100%;
  height: 200px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-subtitle2 textarea {
  width: 100%;
  height: 100%;
  resize: none;
}

.articleEdit-editor-input-subtitle3 {
  width: 100%;
  height: 40px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-subtitle4 {
  width: 100%;
  height: 40px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-content {
  width: 100%;
  height: 40px;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
}

.articleEdit-editor-input-linkContainer {
  width: 100%;
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
  padding-bottom: 6px;
}

.articleEdit-editor-input-link-inputContainer {
  margin: 8px;
}

.articleEdit-editor-input-link-inputContainer label {
  font-size: 14px;
}

.articleEdit-editor-input-link-inputContainer input {
  background-color: #ebf8ff;
  border: 1px solid #0f3676;
  padding: 4px;
  width: 100%;
  font-family: "Ubuntu", sans-serif, "Microsoft JhengHei", "微軟正黑體";
  font-size: 16px;
  font-weight: bold;
  color: teal;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleEdit-editor-input-link-inputContainer input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

/* -------------------------------------------- */

.articleEdit-editor-imgUpload-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  min-height: 180px;
  width: 100%;
  padding: 10px 15px;
}

.articleEdit-editor-imgUpload-preview {
  position: relative;
  min-height: 180px;
  width: 260px;
  border: 2px solid #0f3676;
  border-radius: 5px;
  background-color: #ebf8ff;
  display: block;
}

.articleEdit-editor-imgUpload-preview .img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 200px;
}

.articleEdit-editor-imgUpload-controlContainer {
  min-height: 180px;
  width: 400px;
}

.articleEdit-editor-imgUpload-control-titleContainer {
  display: flex;
  justify-content: space-between;
}

.articleEdit-editor-imgUpload-control-titleContainer h2 {
  margin-top: 10px;
  margin-left: 20px;
  color: #0f3676;
}

.articleEdit-editor-imgUpload-control-titleContainer .right {
  display: flex;
  justify-content: space-between;
  width: 75px;
}

.articleEdit-editor-imgUpload-control-titleContainer .icon {
  margin-bottom: 5px;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.articleEdit-editor-imgUpload-control-titleContainer .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
  border: 2px solid #0f3676;
}

.articleEdit-editor-imgUpload-controlContainer .uploadBtn {
  cursor: pointer;
  padding: 4px 8px;
  /* font-weight: bold; */
  margin-left: 12px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  width: 100px;
  background-color: #0f3676;
  color: white;
  border: 2px solid #0f3676;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleEdit-editor-imgUpload-controlContainer .uploadBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.articleEdit-editor-imgUpload-controlContainer .uploadPath {
  margin-left: 20px;
  font-size: 16px;
  color: #2389b1;
  font-weight: bold;
  font-style: italic;
  overflow-wrap: break-word;
  height: 50px;
  font-family: "Ubuntu", "Microsoft JhengHei", "微軟正黑體", sans-serif;
}

#upload-photo {
  display: none;
}

.articleEdit-labelContainer {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #0f3676;
}

.articleEdit-label {
  cursor: pointer;
  margin-left: 15px;
  padding: 2px;
  padding-bottom: 8px;
  vertical-align: middle;
  font-family: "Ubuntu", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #00638a;
  border: 2px solid #0f3676;
  border-radius: 5px;
  background-color: #c5efff;
}

.articleEdit-label-option {
  cursor: pointer;
  border: 2px solid #0f3676;
}

/* ----------------------------------------------- */

.articleEdit-editor-input-picture-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 24px;
  width: 624px;
  height: 164px;
  padding: 10px;
  border: 2px solid #0f3676;
  border-radius: 10px;
}

.articleEdit-editor-input-picture-preview {
  position: relative;
  width: 198px;
  height: 140px;
  border: 1px solid #0f3676;
  border-radius: 5px;
  background-color: #ebf8ff;
}

.articleEdit-editor-input-picture-preview .img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 160px;
}

.articleEdit-editor-input-picture-rightContainer {
  width: 385px;
  height: 100%;
}

.articleEdit-editor-pictureTitleContainer {
  display: flex;
  justify-content: space-between;
}

.articleEdit-editor-pictureTitleContainer .title {
  color: #0f3676;
}

.articleEdit-editor-picture-iconContainer {
  display: flex;
  justify-content: space-between;
  width: 75px;
}

.articleEdit-editor-picture-iconContainer .icon {
  margin-bottom: 5px;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #0f3676;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.articleEdit-editor-picture-iconContainer .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
  border: 2px solid #0f3676;
}

.articleEdit-editor-input-picture-rightContainer .uploadBtn {
  cursor: pointer;
  padding: 4px 8px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  width: 100px;
  background-color: #0f3676;
  color: white;
  border: 2px solid #0f3676;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleEdit-editor-input-picture-rightContainer .uploadBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.articleEdit-editor-input-picture-rightContainer .uploadPath {
  font-size: 16px;
  color: #2389b1;
  font-weight: bold;
  font-style: italic;
  overflow-wrap: break-word;
  height: 60px;
  font-family: "Ubuntu", "Microsoft JhengHei", "微軟正黑體", sans-serif;
}

.upload-picture-hidden {
  display: none;
}

/* -------------------------------------------------------- */

.articleEdit-editor-inputContainer .codeInput {
  background-color: #111;
  color: white;
}

.articleEdit-editor-inputContainer .codeInput:focus {
  background-color: #111e4a;
}

.articleEdit-editor-input-rightContainer.code {
  width: 210px;
}

.articleEdit-editor-input-rightContainer .custom-select {
  font-family: "Ubuntu";
  background-color: #0f3676;
  color: white;
  /* font-weight: bold; */
  font-size: 16px;
  border-radius: 8px;
  padding-left: 5px;
  width: 120px;
  margin-bottom: 5px;
}

.articleEdit-editor-input-rightContainer option {
  font-family: "Ubuntu";
  font-size: 16px;
  /* font-weight: bold; */
}

/* ---------------------------- */

.articleSubmitBtn {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  min-width: 150px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: 42px;
  border-radius: 10px;
  color: white;
  background-color: #0f3676;
  border: 4px solid #0f3676;
  font-size: 24px;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.articleSubmitBtn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

/* ------------------- Preview ----------------------- */
.article-render-tagContainer {
  border-bottom: 3px solid #0f3676;
  /* margin-bottom: 10px; */
}

.editSnippetTag {
  display: inline-block;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 10px 10px 0 0;
}

.toast-success-container {
  border-radius: 8px;
  background-color: #00638a;
  /* background-color: #2389b1; */
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  font-size: 17px;
  font-weight: bold;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

/* .toast-success-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34a853;
} */

