#partner2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#partner2 a {
  display: block;
  padding: 10px 0;
  flex-basis: 110px;
  height: 130px;
  transition: 0.7s;
}
#partner2 a:hover {
  background-color: #c5efff;
}
