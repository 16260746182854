.subTag {
  position: relative;
}

.subTag .subIcon {
  float: left;
  width: 64px;
  height: 55px;
  padding: 4px 4px 0 4px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 10px 10px 0 0;
  border-width: 3px 3px 0 3px;
  background-color: white;
}

.subTag .title {
  float: right;
  margin-top: 22px;
  width: 288px;
  height: 33px;
  padding-top: 3px;
  border-radius: 0 10px 0 0;
  background-color: #0f3676;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.subContainer {
  padding: 6px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 0 0 10px 10px;
  border-width: 0 3px 3px 3px;
  background-color: white;
}

#rightNewsContainer .news,
#rightNoticeContainer .news {
  border-top: 1px solid #c5efff;
}
#rightNewsContainer .news .date,
#rightNoticeContainer .news .date {
  width: 50px;
  padding-left: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #0f3676;
}
#rightNewsContainer .news .title,
#rightNoticeContainer .news .title {
  width: 269px;
  padding: 2px;
  font-size: 0.9rem;
}

#rightNoticeContainer .news .link {
  color: #2389b1;
  transition: 0.4s;
}
#rightNoticeContainer .news .link:hover {
  color: #dcab1f;
}

#rightNewsContainer .news tr,
#rightNoticeContainer .news tr {
  width: 100%;
}
#rightNewsContainer .news tr:nth-child(odd),
#rightNoticeContainer .news tr:nth-child(odd) {
  background-color: #ebf8ff;
}
#rightNewsContainer .news td,
#rightNoticeContainer .news td {
  padding: 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #c5efff;
}

/* -----------Right Login------------ */

.right-login-switch-btns-container {
  display: flex;
  justify-content: space-evenly;
}

.rightLoginTag {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #2389b1;
  font-size: 18px;
  font-weight: bold;
}

.rightLoginTag.register {
  color: #2389b1;
  font-style: italic;
}

.rightLoginInput {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 300px;
}

.rightLoginInput label {
  display: inline-block;
  background-color: #0f3676;
  padding: 3px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  width: 80px;
  height: 25px;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0 0;
}

.rightLoginInput input {
  font-family: 'Ubuntu', sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  font-size: 16px;
  font-weight: bold;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  border-radius: 0 5px 5px 5px;
  padding: 5px;
  padding-left: 10px;
  color: #0f3676;
  transition: 0.3s;
}

.rightLoginInput input:focus {
  background-color: #c5efff;
}

.rightLoginAssist {
  cursor: pointer;
  color: #20b2aa;
  font-size: 14px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.rightLoginAssist .link {
  vertical-align: middle;
  transition: 0.3s;
}

.rightLoginAssist .link:hover {
  color: #dcab1f;
}

.rememberMeContainer {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.3s;
}

.rememberMeContainer:hover {
  color: #dcab1f;
}

.rememberMeContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.rememberMeContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ebf8ff;
  border: 2px solid #20b2aa;
  border-radius: 3px;
  transition: 0.3s;
}

.rememberMeContainer:hover input ~ .checkmark {
  background-color: #c5efff;
  border-color: #dcab1f;
}

.rememberMeContainer input:checked ~ .checkmark {
  background-color: #c5efff;
}

.rememberMeContainer .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.rememberMeContainer input:checked ~ .checkmark:after {
  display: block;
}

.rememberMeContainer .checkmark:after {
  left: 3px;
  top: 0px;
  width: 8px;
  height: 12px;
  border: solid #0f3676;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rightLoginBtn {
  display: block;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  color: white;
  background-color: #0f3676;
  border: 3px solid #0f3676;
  border-radius: 10px;
  padding: 2px;
  transition: 0.3s;
}
.rightLoginBtn.active {
  background-color: #2389b1;
}

.rightLoginBtn.cancel {
  background-color: #aaa;
}

.rightLoginBtn:hover {
  background-color: #2389b1;
}

.rightLoginBtn.register {
  margin-top: 20px;
  background-color: #2389b1;
  margin-bottom: 30px;
}
.rightLoginBtn.register:hover {
  background-color: #ffd837;
}

.rightLoginInput .description {
  position: relative;
  height: 30px;
}

.rightLoginInput .icon {
  position: absolute;
  right: 0;
  border: 3px solid #0f3676;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #0f3676;
  transition: 0.5s;
  cursor: pointer;
}

.rightLoginInput .icon:hover {
  color: #ffd837;
  background-color: #0f3676;
}

.rightLoginInput .error {
  min-height: 16px;
  font-size: 12px;
  width: 100%;
  color: red;
}

/* ----------------Account Details------------------ */

.notValidate-message {
  color: #0f3676;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
}
.notValidate-message.warning {
  margin-top: 10px;
  color: red;
}
.right-confirmation-btn-container {
  text-align: center;
  margin-bottom: 10px;
}
.right-confirmation-btn {
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 10px;
  background-color: #2389b1;
  border: 3px solid #0f3676;
  border-radius: 6px;
  transition: 0.4s;
}
.right-confirmation-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}

.rightAccountDetails-container {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 63px auto;
}

.rightAccountDetails-title {
  display: flex;
  margin: 20px;
}

.rightAccountDetails-avatarContainer {
  width: 72px;
  height: 72px;
  border-radius: 6px;
  overflow: hidden;
}

.rightAccountDetails-avatar {
  width: 72px;
  height: 72px;
  background-color: #0f3676;
}

.rightAccountDetails-label {
  color: #0f3676;
  font-size: 14px;
  font-weight: bold;
  height: 22px;
  line-height: 22px;
}
.rightAccountDetail-tips {
  color: #2389b1;
  font-weight: bold;
  font-size: 17px;
  margin-left: 10px;
}

.rightAccountDetails-container.chains {
  margin-top: 5px;
  grid-template-columns: 100px auto;
  grid-gap: 5px;
}

.rightAccountDetails-container.chains .rightAccountDetails-label {
  display: flex;
}

.rightAccountDetails-label img {
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 6px;
}

.rightAccountDetails-value {
  color: #0f3676;
  font-size: 13px;
  font-weight: bold;
  min-height: 22px;
  line-height: 22px;
}

.rightAccountDetails-container.chains .rightAccountDetails-label {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
}
.rightAccountDetails-container.chains .rightAccountDetails-value {
  font-family: 'ubuntu', sans-serif;
  font-size: 15px;
  height: 28px;
  line-height: 28px;
  color: #2389b1;
}

.rightAccountTag {
  font-size: 14px;
  text-align: center;
  color: #2389b1;
  font-weight: bold;
}

.right-account-admin-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.right-account-admin-btn {
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  font-size: 17px;
  border-radius: 7px;
  border: 3px solid #0f3676;
  padding: 1px 10px;
  transition: 0.4s;
}

.right-account-admin-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.right-eoscity-statistic-table {
  color: #0f3676;
  font-size: 14px;
}
.right-eoscity-statistic-row {
  display: grid;
  grid-template-columns: 100px auto;
  padding: 3px 2px;
  border-bottom: 1px solid #c5efff;
}
.right-eoscity-statistic-row:nth-child(odd) {
  background-color: white;
}
.right-eoscity-statistic-row:nth-child(even) {
  background-color: #ebf8ff;
}
