#leftZone {
  grid-area: leftZone;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}

.nav1 .leftContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 1px;
  width: 30px;
  min-height: 900px;
}
.nav1 .rightContainer {
  min-height: 1100px;
  position: absolute;
  left: 26px;
  width: 224px;
}
.nav1 .rightContainer .upperpart {
  padding: 10px 0 0 0;
  border: solid #0f3676;
  border-width: 4px 4px 0 4px;
  border-radius: 0 10px 0 0;
}

.nav1 .button {
  cursor: pointer;
  position: relative;
  width: 30px;
  flex-basis: 100px;
  background-color: #0f3676;
  border-width: 4px 0 4px 4px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 10px 0 0 10px;
  transition: 0.3s;
}
.nav1 .button:hover {
  border-color: #2389b1;
  background-color: #2389b1;
}
.nav1 .button span {
  position: absolute;
  top: 70px;
  left: -42px;
  transform: rotate(90deg);
  width: 112px;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}
.nav1 .button.active {
  background-color: white;
}
.nav1 .button.active span {
  color: #0f3676;
  font-weight: bold;
}

.content {
  display: none;
}

.content.active {
  display: block;
}

.left-title {
  background-color: #0f3676;
  border-radius: 7px 7px 0 0;
  margin: 3px 20px 10px;
  padding: 1px 15px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

/* ---------------Market Part---------------------- */

.left-title.market {
  background-color: #ffd837;
  color: #0f3676;
}

.left-first-priceList-container {
  margin-bottom: 20px;
  padding: 0 5px;
}

.left-first-priceList-container .coinContainer {
  cursor: pointer;
  font-family: '微軟正黑體', 'Microsoft JhengHei';
  display: grid;
  grid-template-columns: 70px auto 25px;
  color: #222;
  font-size: 12px;
  border-bottom: 1px solid #c5efff;
  height: 20px;
  line-height: 20px;
  transition: 0.5s;
}

.left-first-priceList-container .coinContainer:nth-child(odd) {
  background-color: #ebf8ff;
}
.left-first-priceList-container .coinContainer:nth-child(even) {
  background-color: white;
}
.left-first-priceList-container .coinContainer:hover {
  background-color: #ffd837;
}

.left-first-priceList-container .coinContainer.title {
  background-color: #c5efff;
  color: #0f3676;
}

.left-first-priceList-container .coinContainer .coin {
  margin-left: 20px;
}
.left-first-priceList-container .coinContainer .price {
  margin-right: 5px;
  text-align: right;
  transition: 0.4s;
  font-weight: bold;
}
.left-first-priceList-container .coinContainer .price.up {
  color: #2ecc71;
}
.left-first-priceList-container .coinContainer .price.down {
  color: #e74c3c;
}

.left-priceList-container {
  padding: 0 5px 20px;
}

.left-priceList-container .coinContainer {
  cursor: pointer;
  font-family: '微軟正黑體', 'Microsoft JhengHei';
  display: grid;
  grid-template-columns: 70px 65px auto;
  color: #222;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  transition: 0.5s;
  border-bottom: 1px solid #c5efff;
}

.left-priceList-container .coinContainer:nth-child(odd) {
  background-color: white;
}
.left-priceList-container .coinContainer:nth-child(even) {
  background-color: #ebf8ff;
}

.left-priceList-container .coinContainer:hover {
  background-color: #ffd837;
}

.left-priceList-container .coinContainer.up {
  background-color: #ccffcc;
}
.left-priceList-container .coinContainer.down {
  background-color: #ffcccc;
}

.left-priceList-container .coinContainer .coin {
  margin-left: 8px;
  color: #0f3676;
  font-weight: bold;
}
.left-priceList-container .coinContainer .volume {
  margin-right: 8px;
  text-align: right;
}

.left-priceList-container .coinContainer.title {
  background-color: #c5efff;
  color: #0f3676;
}
.left-priceList-container .coinContainer.title .coin {
  color: #0f3676;
}

/* -----------------------Account Part-------------------- */

.left-control-panel-slogan {
  color: #2389b1;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.left-control-panel-slogan.bottom {
  color: #555;
}
.left-control-panel-slogan.myArticles {
  padding-left: 8px;
  padding-right: 8px;
}

.left-title.extend {
  cursor: pointer;
  transition: 0.4s;
}
.left-title.extend:hover {
  background-color: #2389b1;
}

.left-control-panel-btns-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.left-control-panel-btn-container {
  cursor: pointer;
  display: block;
  width: 55px;
  height: 60px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  color: #2389b1;
  transition: 0.4s;
}

.left-control-panel-btn-container:hover {
  background-color: #c5efff;
}

.left-control-panel-btn-img {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.left-account-admin-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.left-account-admin-btn {
  cursor: pointer;
  background-color: #0f3676;
  color: white;
  font-size: 12px;
  padding: 1px 3px;
  border-radius: 6px;
  border: 2px solid #0f3676;
  transition: 0.4s;
}
.left-account-admin-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}

.left-account-admin-btn.myAccount {
  background-color: #2389b1;
  border-width: 3px;
}
.left-account-admin-btn.myAccount:hover {
  background-color: #ffd837;
}

.left-account-switch-btns-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.left-account-admin-btn.switch:hover {
  color: white;
  background-color: #2389b1;
}
.left-account-admin-btn.switch.active {
  background-color: #2389b1;
}

.left-eoscity-statistic-table {
  color: #0f3676;
  font-size: 12px;
  padding: 3px;
}
.left-eoscity-statistic-row {
  display: grid;
  grid-template-columns: 90px auto;
  border-bottom: 1px solid #c5efff;
  padding: 3px 5px;
}
.left-eoscity-statistic-row:nth-child(odd) {
  background-color: white;
}
.left-eoscity-statistic-row:nth-child(even) {
  background-color: #ebf8ff;
}

.left-notValidate-container {
  padding: 0 10px;
}

/* -----------left-footer----------- */
.left-footer-container {
  background-color: #0f3676;
  width: 224px;
  border-radius: 0 0 10px 10px;
  padding: 15px;
  padding-bottom: 25px;
}

.left-footer-container .first-row {
  display: flex;
  justify-content: space-evenly;
}
.left-footer-container .first-row .shortcut-container {
  cursor: pointer;
  color: white;
  width: 28px;
  text-align: center;
  font-size: 14px;
  opacity: 0.6;
  transition: 0.4s;
}
.left-footer-container .first-row .shortcut-container:hover {
  opacity: 1;
}

.left-footer-container .first-row .shortcut-container.active {
  opacity: 1;
}

.left-footer-container .first-row .icon {
  display: block;
  width: 28px;
}
.left-footer-container .second-row {
  margin-top: 15px;
  height: 32px;
}

.left-footer-container .second-row .logo {
  display: block;
  margin: auto;
  height: 32px;
}

.left-footer-container .third-row {
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.left-footer-container .third-row .contact-container {
  height: 32px;
}

.left-footer-container .third-row .contact {
  display: block;
  cursor: pointer;
  width: 32px;
  opacity: 0.5;
  transition: 0.4s;
}

.left-footer-container .third-row .contact:hover {
  opacity: 1;
}
