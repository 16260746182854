.emailConfirmation-container {
  padding: 0 40px;
  min-height: 1000px;
}

.emailConfirmation-header {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #0f3676;
}

.emailConfirmation-title-container {
  display: flex;
}

.emailConfirmation-avatar-container {
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.emailConfirmation-avatar {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: #0f3676;
}

.emailConfirmation-p-container {
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  color: #2389b1;
}

.emailConfirmation-p-container.title {
  margin-left: 20px;
  width: 650px;
  color: #0f3676;
}

.emailConfirmation-btn {
  cursor: pointer;
  color: white;
  background-color: #0f3676;
  font-size: 26px;
  font-weight: bold;
  padding: 2px 18px;
  border-radius: 10px;
  border: 3px solid #0f3676;
  transition: 0.4s;
}
.emailConfirmation-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}
