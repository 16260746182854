#middleZone-home {
  grid-area: middleZone;
  min-height: 1100px;
}

.middleZone-upper {
  width: 810px;
  min-height: 514px;
  margin-bottom: 6px;
}

.middleZone-upper-bannerContainer {
  position: relative;
  margin-bottom: 4px;
  width: 810px;
  height: 378px;
}

.middleZone-upper-banner {
  position: absolute;
  z-index: 0;
  opacity: 0;
  width: 810px;
  height: 378px;
  transition-duration: 0.5s;
}
.middleZone-upper-banner.active {
  z-index: 1;
  opacity: 1;
}

.middleZone-upper-thumbsContainer {
  display: flex;
  justify-content: space-between;
  width: 810px;
  min-height: 132px;
}

.middleZone-upper-thumbContainer {
  width: 132px;
  height: 132px;
}

.middleZone-upper-thumbnail-imgContainer {
  width: 132px;
  height: 62px;
}

.middleZone-upper-thumbnail-img {
  display: block;
  width: 132px;
  height: 62px;
}

.middleZone-upper-thumbnail-textContainer {
  width: 132px;
  height: 64px;
  padding: 5px;
  font-size: 13px;
  color: #0f3676;
  transition: 0.75s;
}

.middleZone-upper-thumbnail-textContainer.active {
  background-color: #c5efff;
  color: #2389b1;
}

.middleZone-upper-thumbnail-highlight {
  background-color: white;
}

.middleZone-upper-thumbnail-highlight.active {
  background-color: #2389b1;
  height: 6px;
}

.middleZone-upper-edit-thumbContainer {
  width: 132px;
  background-color: #c5efff;
}

.middleZone-upper-thumbnail-edit-textarea {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  vertical-align: top;
  width: 132px;
  height: 64px;
  font-size: 13px;
  resize: none;
  border: 2px solid #0f3676;
  color: #0f3676;
  padding: 4px;
  background-color: #ebfeff;
  transition: 0.4s;
  overflow: hidden;
}
.middleZone-upper-thumbnail-edit-textarea:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
.middleZone-upper-thumbnail-edit-btns-container {
  display: flex;
  justify-content: space-between;
  background-color: #0f3676;
  height: 30px;
  line-height: 30px;
  color: white;
  font-size: 15px;
  padding: 0 8px;
}
.middleZone-upper-thumbnail-edit-btn-container {
  cursor: pointer;
  transition: 0.4s;
}
.middleZone-upper-thumbnail-edit-btn-container:hover {
  color: #ffd837;
}
.middleZone-upper-thumbnail-edit-url-input-container {
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  background-color: #0f3676;
  color: white;
}
.middleZone-upper-thumbnail-url-input-label {
  font-size: 12px;
}
.middleZone-upper-thumbnail-url-input {
  margin-left: 5px;
  padding-left: 3px;
  width: 90px;
  border-width: 0;
  height: 20px;
  border-radius: 6px;
}
/* ------------------------------------------------------------------- */

.middle-bottom-articleTypeBarContainer {
  display: flex;
  flex-wrap: wrap;
  width: 810px;
}

.middle-bottom-articleTypeBtn {
  cursor: pointer;
  flex-basis: 101.25px;
  padding: 4px 0;
  background-color: #0f3676;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: 0.3s;
}

.middle-bottom-articleTypeBtn:hover {
  background-color: #2389b1;
}

.middle-bottom-articleTypeBtn.active {
  background-color: #2389b1;
}
