#rightZone {
  grid-area: rightZone;
}

.nav2 {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}
.nav2 .topContainer {
  display: flex;
}
.nav2 .topIcon {
  cursor: pointer;
  flex-basis: 76px;
  height: 60px;
  background-color: #0f3676;
  border-style: solid;
  border-width: 4px 0 0 0;
  border-color: #0f3676;
  transition: 0.3s;
}
.nav2 .topIcon:first-child {
  border-width: 4px 0 0 4px;
  border-radius: 20px 0 0 0;
}
.nav2 .topIcon:last-child {
  border-width: 4px 4px 0 0;
  border-radius: 0 20px 0 0;
}
.nav2 .topIcon:hover {
  background-color: #2389b1;
  border-color: #2389b1;
}
.nav2 .topIcon.active {
  background-color: white;
}

.nav2_topIconContainer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6px;
  width: 44px;
}

.nav2 .rightContainer {
  position: relative;
  width: 100%;
  min-height: 900px;
  border-style: solid;
  border-width: 0 4px;
  border-color: #0f3676;
}

.nav2-subZoneIcon {
  padding-top: 20px;
}

.nav2-titleIcon {
  background-color: #0f3676;
  border-radius: 10px 10px 0 0;
  margin: 0 20px 10px;
  padding: 1px 15px;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.nav2-iconsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav2-iconsContainer a {
  display: block;
  cursor: pointer;
  flex-basis: 93px;
  height: 93px;
  transition: 0.3s;
}
.nav2-iconsContainer a:hover {
  background-color: #c5efff;
}
/* .nav2-iconContainer {
  display: none;
}
.nav2-iconContainer.active {
  display: block;
} */
.nav2-iconContainer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 71px;
  height: 71px;
}
.nav2-iconContainer-text {
  /* width: 100%; */
  text-align: center;
  font-size: 1.05rem;
  font-weight: bold;
  color: #0f3676;
}

/* ----------------------------------------- */

.nav2-subZone {
  padding: 10px;
}

/* ---------------------Navigation------------------ */

.right-nav-addNewClassBtn-container {
  text-align: center;
}
.right-nav-addNewClassBtn {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #0f3676;
  padding: 2px 12px;
  border-radius: 12px;
  border: 3px solid #0f3676;
  transition: 0.5s;
}
.right-nav-addNewClassBtn:hover {
  background-color: #2389b1;
}

.right-nav-title-path-input-container {
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
  background-color: #2389b1;
  padding: 10px;
  margin: 0 10px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  border-radius: 12px;
}

.right-nav-title-path-input-container.saved {
  opacity: 1;
  background-color: #0f3676;
}

.right-nav-title-path-input-container .icon {
  position: relative;
  top: -1px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  /* vertical-align: middle; */
  /* background-color: white; */
  color: white;
  transition: 0.4s;
}
.right-nav-title-path-input-container .icon:hover {
  background-color: white;
  color: #2389b1;
}

.right-nav-title-input {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  height: 28px;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #0f3676;
  width: 95px;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  border-radius: 6px;
  text-align: center;
  transition: 0.5s;
}
.right-nav-title-input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
.right-nav-icon-group-edit-container {
  display: flex;
  flex-wrap: wrap;
}
.right-nav-icon-edit-container-outer {
  width: 93px;
  padding-top: 10px;
  height: 155px;
}
.right-nav-icon-edit-container {
  opacity: 0.6;
  background-color: #2389b1;
  margin: auto;
  width: 88px;
  height: 145px;
  padding-top: 8px;
  border-radius: 8px;
}
.right-nav-icon-edit-container.saved.withoutContent {
  opacity: 1;
}
.right-nav-icon-edit-container.saved.withContent {
  opacity: 1;
  background-color: #0f3676;
}
.right-nav-icon-edit-img-container {
  display: block;
  margin: auto;
  border-radius: 6px;
  width: 70px;
  height: 70px;
  background-color: white;
  overflow: hidden;
  transition: 0.5s;
}
.right-nav-icon-edit-img {
  cursor: pointer;
  transition: 0.4s;
}
.right-nav-icon-edit-img:hover {
  background-color: #2389b1;
}
.right-nav-icon-edit-input-container {
  margin-top: 6px;
  text-align: center;
}
.right-nav-icon-edit-input {
  font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei';
  width: 70px;
  background-color: #ebf8ff;
  border: 2px solid #0f3676;
  border-radius: 4px;
  text-align: center;
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #0f3676;
  transition: 0.5s;
}
.right-nav-icon-edit-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}
.right-nav-icon-edit-modify-container {
  margin: auto;
  margin-top: 6px;
  width: 86px;
  display: flex;
  justify-content: space-evenly;
  /* background-color: white; */
  height: 24px;
}
.right-nav-icon-edit-modify-container .icon {
  cursor: pointer;
  border-radius: 50%;
  /* border: 2px solid #073676; */
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  /* background-color: #0f3676; */
  background-color: #2389b1;
  color: white;
  transition: 0.5s;
}
.right-nav-icon-edit-modify-container .icon.plus {
  line-height: 24px;
}
.right-nav-icon-edit-modify-container .icon.trash {
  background-color: #2389b1;
  color: white;
}
.upload-icon {
  display: none;
}
.right-nav-icon-edit-modify-container .icon:hover {
  background-color: #ffd837;
  color: #0f3676;
}
.right-nav-icon-edit-modify-container .icon.trash:hover {
  background-color: white;
  color: #2389b1;
}
.right-nav-icon-add-container {
  padding-top: 31px;
  width: 93px;
  height: 155px;
}
.right-nav-icon-add {
  cursor: pointer;
  filter: saturate(80%);
  opacity: 0.8;
  transition: 0.4s;
}
.right-nav-icon-add:hover {
  opacity: 1;
  filter: saturate(120%);
}
