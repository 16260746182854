/* .dynamicPanel-container {
} */

.right-dynamicPanel-title-container {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  display: flex;
  width: 100%;
}
.right-dynamicPanel-title-icon-container {
  width: 64px;
  height: 55px;
  padding: 4px 4px 0 4px;
  border-style: solid;
  border-color: #0f3676;
  border-radius: 10px 10px 0 0;
  border-width: 3px 3px 0 3px;
  background-color: white;
}
.right-dynamicPanel-title-icon-container .icon {
  width: 50px;
  border-radius: 6px;
  /* background-color: #0f3676; */
}
.right-dynamicPanel-title-container .tagContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(100% - 64px);
}
.right-dynamicPanel-title-container .tag {
  cursor: pointer;
  width: 100%;
  background-color: #0f3676;
  color: white;
  height: 33px;
  line-height: 33px;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  border-radius: 0 10px 0 0;
  transition: 0.5s;
  border-bottom: 3px solid #0f3676;
}
.right-dynamicPanel-title-container .tag:hover {
  background-color: #2389b1;
  color: white;
}

.right-dynamicPanel-content-container {
  border: 6px solid #0f3676;
  border-width: 0 0 8px;
  border-radius: 0 0 6px 6px;
}

.right-dynamicPanel-content-container.active {
  background-color: white;
  border-radius: 0 0 10px 10px;
  border: 3px solid #0f3676;
  border-top: 0;
  padding: 6px;
}
.right-dynamicPanel-content {
  display: none;
}

.right-dynamicPanel-content.active {
  display: block;
}

/* ----------------Article Edit------------------ */

.right-edit-snippet-container {
  display: flex;
  justify-content: space-between;
  min-height: 65px;
  border-bottom: 1px solid #c5efff;
}

.right-edit-snippet-container:nth-child(even) {
  background-color: #ebf8ff;
}

.right-edit-snippet-content-container {
  width: 308px;
  display: flex;
  padding: 3px;
  padding-right: 6px;
  font-weight: bold;
  font-size: 14px;
  color: #0f3676;
}

.right-edit-snippet-img-container {
  height: 65px;
  padding: 5px;
}

.right-edit-snippet-img {
  height: 55px;
}

.right-edit-snippet-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-edit-snippet-edit-container {
  width: 25px;
}

.right-edit-snippet-edit-container .edit-btn {
  margin-top: 3px;
}
.right-edit-snippet-edit-container .remove-btn {
  margin-top: 5px;
}

.right-edit-snippet-edit-container .editIcon {
  cursor: pointer;
  color: #2389b1;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #2389b1;
  transition: 0.4s;
}
.right-edit-snippet-edit-container .editIcon:hover {
  background-color: #0f3676;
  border-color: #0f3676;
  color: #ffd837;
}

.right-edit-snippet-edit-container .removeIcon {
  cursor: pointer;
  color: #ff3333;
  height: 24px;
  width: 24px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  border: 2px solid #ff3333;
  transition: 0.4s;
}
.right-edit-snippet-edit-container .removeIcon:hover {
  background-color: #ff3333;
  color: white;
}

.right-edit-snippet-content-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.right-edit-snippet-content-info .loves,
.right-edit-snippet-content-info .time {
  font-weight: normal;
  font-size: 12px;
  color: #3c7cbd;
}
