.articleManager-container {
  min-height: 1100px;
}

.articleManager-search-panel {
  display: grid;
  grid-template-columns: 80px 190px 80px 190px 80px 190px;
  color: white;
  background-color: #0f3676;
}

.articleManage-search-cell {
  padding: 2px;
  font-size: 15px;
  padding-left: 10px;
  padding-top: 10px;
}

.articleManager-search-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  padding: 2px;
  padding-left: 5px;
  width: 165px;
  border-width: 0;
  height: 20px;
  border-radius: 4px;
  transition: 0.4s;
  color: #0f3676;
  background-color: #ebf8ff;
}

.articleManager-search-input:focus {
  background-color: #c5efff;
}

.articleManager-search-input-cell {
  padding: 2px;
  padding-top: 10px;
}
.articleManager-search-bar {
  text-align: right;
  background-color: #0f3676;
}

.articleManager-search-btn {
  display: inline-block;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 20px;
  width: 60px;
  font-size: 17px;
  font-weight: bold;
  background-color: #2389b1;
  color: white;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 6px;
  border-width: 0;
  transition: 0.4s;
}

.articleManager-search-btn:hover {
  color: #0f3676;
  background-color: #ffd837;
}
.articleManager-list-container {
  display: grid;
  grid-template-columns: 400px 10px 400px;
}

.articleManager-list-inner-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #0f3676;
  padding: 10px 0;
}

.articleManager-list-inner-title.community {
  color: #00638a;
}

.articleManager-list-snippet-container {
  font-size: 14px;
  color: #0f3676;
  font-weight: bold;
  border-bottom: 1px solid #c5efff;
}
.articleManager-list-snippet-upper-container {
  display: flex;
}
.articleManager-list-snippet-description-container {
  width: 100%;
}

.articleManager-list-snippet-img-container {
  padding: 5px;
}

.articleManager-list-snippet-img {
  display: block;
  max-height: 50px;
  max-width: 85px;
  background-color: #c5efff;
}

.articleManager-list-snippet-container:nth-child(odd) {
  background-color: #ebf8ff;
}
.articleManager-list-snippet-container:nth-child(even) {
  background-color: white;
}

.articleManager-list-snippet-container.community:nth-child(odd) {
  background-color: white;
}
.articleManager-list-snippet-container.community:nth-child(even) {
  background-color: #ebf8ff;
}

.articleManager-list-snippet-row {
  display: flex;
  padding: 4px;
}
.articleManager-list-snippet-link {
  color: #0f3676;
  transition: 0.4s;
}
.articleManager-list-snippet-link:hover {
  color: #2389b1;
}

.articleManager-list-snippet-row.author {
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.articleManager-list-snippet-publish-btns-container {
  color: #0f3676;
  font-size: 13px;
  font-weight: bold;
}
.articleManager-list-snippet-publish-btns-container label {
  cursor: pointer;
}
.articleManager-list-snippet-publish-btns-container input {
  cursor: pointer;
}

.articleManager-list-snippet-last-row {
  display: flex;
  justify-content: space-between;
  color: #3c7cbd;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 5px;
}

.articleManager-list-snippet-btns-container {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 55px;
}

.articleManager-list-snippet-btns-container .editIcon {
  cursor: pointer;
  color: #2389b1;
  height: 24px;
  width: 24px;
  line-height: 19px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #2389b1;
  transition: 0.4s;
}
.articleManager-list-snippet-btns-container .editIcon:hover {
  background-color: #0f3676;
  border-color: #0f3676;
  color: #ffd837;
}

.articleManager-list-snippet-btns-container .removeIcon {
  cursor: pointer;
  color: #ff3333;
  height: 24px;
  width: 24px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  border: 2px solid #ff3333;
  transition: 0.4s;
}
.articleManager-list-snippet-btns-container .removeIcon:hover {
  background-color: #ff3333;
  color: white;
}

.articleManager-label {
  cursor: pointer;
  font-family: 'Microsoft JhengHei', '微軟正黑體';
  height: 20px;
  border-width: 0;
  font-size: 14px;
  font-weight: bold;
  background-color: #ebf8ff;
  color: #0f3676;
  border-radius: 4px;
}

.articleManager-list-slogan {
  text-align: center;
  color: #2389b1;
  font-size: 17px;
  font-weight: bold;
}
