.coinConfigContainer {
  height: 80px;
  padding: 8px;
}
.coinTypeContainer {
  display: grid;
  grid-template-columns: 80px 80px 80px auto;
}
.coinTypeBtnContainer {
  cursor: pointer;
  height: 70px;
  width: 80px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  color: #222;
  transition: 0.5s;
  font-weight: bold;
}
.coinTypeBtnContainer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  filter: grayscale(100%);
  opacity: 0.8;
  transition: 0.3s;
}
.coinTypeBtnContainer img:hover {
  filter: grayscale(0%);
  opacity: 1;
}
.coinTypeBtnContainer:hover {
  color: #2389b1;
}

.coinTypeBtnContainer.active {
  color: #2389b1;
}
.coinTypeBtnContainer.active img {
  filter: grayscale(0%);
  opacity: 1;
}

.coinTypeContainer .coinQuickSearch {
  color: #0f3676;
  font-size: 17px;
  font-weight: bold;
}

.coinTypeContainer .coinQuickSearch input {
  margin-top: 3px;
  width: 100px;
  border: 2px solid #0f3676;
  font-size: 16px;
  padding: 3px;
  border-radius: 5px;
  background-color: #ebf8ff;
  font-weight: bold;
  color: #0f3676;
  transition: 0.4s;
}

.coinTypeContainer .coinQuickSearch input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.coinQuotationContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84px;
  padding: 6px 10px 10px;
}

.coinQuotationContainer:nth-child(odd) {
  background-color: white;
  color: #0f3676;
}
.coinQuotationContainer:nth-child(even) {
  background-color: #0f3676;
  color: white;
}

.coinQuotationContainer .firstRow {
  /* font-family: 'ubuntu', sans-serif, '微軟正黑體', 'Microsoft JhengHei'; */
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 17px;
}

.coinQuotationContainer .firstRow span {
  font-size: 20px;
}

.coinQuotationContainer .price {
  transition: 0.25s;
}
.coinQuotationContainer:nth-child(odd) .price {
  color: #0f3676;
}
.coinQuotationContainer:nth-child(even) .price {
  color: white;
}
.coinQuotationContainer .price.up {
  color: #2ecc71;
}
.coinQuotationContainer .price.down {
  color: #e74c3c;
}

.coinQuotationContainer .secondRow {
  display: grid;
  grid-template-columns: 135px 135px auto;
  font-size: 14px;
}

.coinQuotationContainer .secondRow .change {
  color: #e74c3c;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}
.coinQuotationContainer .secondRow .change.up {
  color: #2ecc71;
}

.coinQuotationContainer .thirdRow {
  display: grid;
  grid-template-columns: 160px 140px auto;
  font-size: 13px;
}

.coinQuotationContainer .thirdRow .trade {
  width: 100%;
  height: 100%;
  background-color: #ffd837;
  text-align: center;
  border-radius: 3px;
  color: #0f3676;
}
