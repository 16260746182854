.middle-navigator-wrapper {
  margin: 0 20px;
  min-height: 1100px;
}

.middle-navigator-header-container {
  display: flex;
}

.middle-navigator-img-container {
  height: 80px;
  width: 80px;
  /* background-color: #c5efff; */
}

.middle-navigator-title-container {
  height: 80px;
  padding-left: 40px;
  line-height: 80px;
  font-size: 40px;
  font-weight: bold;
  color: #0f3676;
}

.middle-navigator-subtitle-container {
  margin-top: 30px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: #0f3676;
}

.middle-navigator-intro-container {
  white-space: pre-line;
  margin-top: 27px;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  color: #0f3676;
}

.middle-navigator-content-container {
  white-space: pre-line;
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #0f3676;
}
.middle-navigator-link-container {
  display: block;
  margin-top: 25px;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  color: #20b2aa;
  transition: 0.4s;
}
.middle-navigator-link-container:hover {
  color: #dcab1f;
}

.middle-navigator-recommendSnippet-container {
  white-space: pre-line;
  display: flex;
  color: #0f3676;
  border-bottom: 2px solid #c5efff;
}

.middle-navigator-recommendSnippet-left-avatar-container {
  width: 100px;
}

.middle-navigator-recommendSnippet-left-avatar {
  display: block;
  margin: auto;
  margin-top: 20px;
  width: 60px;
  height: 60px;
}

.middle-navigator-recommendSnippet-left-avatar-label {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  width: 100px;
  padding-bottom: 15px;
}
.middle-navigator-recommendSnippet-right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 670px;
  padding: 5px;
  padding-left: 15px;
}
.middle-navigator-recommendSnippet-right-title {
  white-space: pre-line;
  margin-top: 12px;
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;
  color: #0f3676;
}
.middle-navigator-recommendSnippet-right-intro {
  white-space: pre-line;
  margin-top: 9px;
  font-size: 17px;
  line-height: 25px;
  font-weight: bold;
  color: #0f3676;
}
.middle-navigator-recommendSnippet-right-content {
  white-space: pre-line;
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #0f3676;
}
.middle-navigator-recommendSnippet-right-link {
  margin-top: 9px;
  font-size: 17px;
  line-height: 25px;
  font-weight: bold;
  color: #20b2aa;
  transition: 0.4s;
}
.middle-navigator-recommendSnippet-right-link:hover {
  color: #dcab1f;
}
.middle-navigator-recommendSnippet-right-lower {
  display: flex;
  justify-content: flex-end;
}
.middle-navigator-recommendSnippet-right-contactUrlIconBar-container {
  display: flex;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon-container {
  cursor: pointer;
  filter: grayscale(1);
  opacity: 0.65;
  height: 46px;
  transition: 0.4s;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon-container:hover {
  filter: grayscale(0);
  opacity: 1;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon {
  display: block;
  width: 40px;
  height: 33px;
}
.middle-navigator-recommendSnippet-right-contactUrlIcon-label {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #2389b1;
}

/* ------------------------EDIT MODE------------------------------- */

.middle-navigator-title-edit-container {
  padding-left: 30px;
  padding-top: 15px;
}

.middle-navigator-title-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  padding: 10px;
  background-color: #ebf8ff;
  height: 50px;
  width: 400px;
  border: 2px solid #0f3676;
  border-radius: 10px;
  font-size: 30px;
  color: #0f3676;
  font-weight: bold;
  transition: 0.4s;
}
.middle-navigator-title-input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.middle-navigator-edit-insert-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.middle-navigator-edit-insert-container .title {
  width: 200px;
  height: 44px;
  border-radius: 10px 0 0 10px;
  border: 2px solid #0f3676;
  background-color: #0f3676;
  font-size: 18px;
  font-weight: bold;
  color: white;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.middle-navigator-edit-insert-container .title .arrow {
  color: #ffd837;
}
.middle-navigator-edit-insert-container .btnContainer {
  width: 570px;
  background-color: #ebf8ff;
  border-radius: 0 10px 10px 0;
  border: 2px solid #0f3676;
  height: 44px;
  display: flex;
  justify-content: space-evenly;
}

.middle-navigator-edit-insert-container .btnContainer .btn {
  cursor: pointer;
  margin-top: 6px;
  background-color: #0f3676;
  height: 28px;
  color: white;
  line-height: 28px;
  width: 80px;
  text-align: center;
  font-size: 15px;
  border-radius: 5px;
  transition: 0.4s;
}
.middle-navigator-edit-insert-container .btnContainer .btn:hover {
  background-color: #2389b1;
}

/* .middle-navigator-edit-paragraph-container {
  background-color: #ebf8ff;
} */

.middle-navigator-edit-paragraph-upper-container {
  display: flex;
  justify-content: space-between;
}

.middle-navigator-edit-snippet-right-icon-rightSide {
  display: flex;
  justify-content: space-between;
  width: 55px;
}

.middle-navigator-edit-snippet-right-icon-rightSide.contactUrl {
  margin-top: 4.5px;
}

.middle-navigator-edit-paragraph-tag-container {
  display: inline-block;
  height: 28px;
  border-radius: 5px 5px 0 0;
  line-height: 28px;
  background-color: #0f3676;
  color: white;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}

.middle-navigator-edit-paragraph-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  border-radius: 0 8px 8px 8px;
  border: 2px solid #0f3676;
  width: 100%;
  resize: none;
  background-color: #ebf8ff;
  color: #0f3676;
  margin-bottom: 20px;
  transition: 0.4s;
}

.middle-navigator-edit-paragraph-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.middle-navigator-edit-paragraph-input.subtitle {
  font-size: 24px;
  font-weight: bold;
  padding: 8px;
}

.middle-navigator-edit-paragraph-input.intro {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.middle-navigator-edit-paragraph-input.contentParagraph {
  min-height: 50px;
  font-size: 16px;
  padding: 10px;
}

.middle-navigator-edit-paragraph-input.link-container {
  background-color: white;
  padding: 15px;
}
.middle-navigator-edit-paragraph-input.link-container .label {
  font-size: 16px;
  font-weight: bold;
}

.middle-navigator-edit-paragraph-input.link-container .label-input {
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
  color: #20b2aa;
  border: 2px solid #0f3676;
  border-radius: 8px;
  padding: 3px;
  padding-left: 10px;
  background-color: #ebf8ff;
  transition: 0.4s;
}
.middle-navigator-edit-paragraph-input.link-container .label-input.link {
  color: #0f3676;
  font-weight: normal;
  font-size: 16px;
  transition: 0.4s;
}
.middle-navigator-edit-paragraph-input.link-container .label-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.middle-navigator-edit-paragraph-input.snippet-container {
  background-color: white;
  display: flex;
}
.middle-navigator-edit-paragraph-input.snippet-container .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px;
}
.middle-navigator-edit-paragraph-input.snippet-container .right {
  width: 670px;
}

.middle-navigator-edit-snippet-icon-container {
  width: 100px;
  height: 80px;
  padding-top: 20px;
  margin-bottom: 8px;
}
.middle-navigator-edit-snippet-icon-uploadBtn {
  display: block;
  cursor: pointer;
  text-align: center;
  margin: auto;
  margin-bottom: 8px;
  width: 70px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background-color: #0f3676;
  color: white;
  border-radius: 8px;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-icon-uploadBtn:hover {
  background-color: #2389b1;
}
.middle-navigator-edit-snippet-icon {
  display: block;
  margin: auto;
  width: 60px;
  height: 60px;
}

.middle-navigator-edit-snippet-content-container {
  padding: 10px;
}
.middle-navigator-edit-snippet-content-insertBar-container {
  display: flex;
}
.middle-navigator-edit-snippet-content-insertBar-label {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px 0 0 8px;
  background-color: #0f3676;
  color: white;
}
.middle-navigator-edit-snippet-content-insertBar {
  display: flex;
  justify-content: space-evenly;
  height: 36px;
  padding: 4px;
  width: 75%;
  border: 2px solid #0f3676;
  border-radius: 0 8px 8px 0;
  background-color: #ebf8ff;
  margin-bottom: 12px;
}

.middle-navigator-edit-snippet-content-container .arrow {
  color: #ffd837;
}

.middle-navigator-edit-snippet-content-insertBar .btn {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  background-color: #0f3676;
  color: white;
  font-size: 15px;
  transition: 0.4s;
}

.middle-navigator-edit-snippet-content-insertBar .btn:hover {
  background-color: #2389b1;
}

.middle-navigator-edit-snippet-icon-title-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  margin: auto;
  display: block;
  border: 2px solid #0f3676;
  color: #0f3676;
  width: 80px;
  height: 28px;
  line-height: 24px;
  background-color: #ebf8ff;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-icon-title-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}
.middle-navigator-edit-snippet-content-subContainer {
  margin-bottom: 12px;
}
.middle-navigator-edit-snippet-content-label {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px 6px 0 0;
  background-color: #0f3676;
  color: white;
  font-size: 15px;
}
.middle-navigator-edit-snippet-content-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  display: block;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  width: 98%;
  border-radius: 0 6px 6px 6px;
  border: 2px solid #0f3676;
  color: #0f3676;
  background-color: #ebf8ff;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-content-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}
.middle-navigator-edit-snippet-content-input.title {
  font-size: 22px;
  height: 34px;
  font-weight: bold;
}
.middle-navigator-edit-snippet-content-input.intro {
  resize: none;
  font-size: 18px;
  font-weight: bold;
}
.middle-navigator-edit-snippet-content-input.content {
  resize: none;
  font-size: 16px;
}
.middle-navigator-edit-snippet-content-input.link {
  background-color: white;
}
.middle-navigator-edit-snippet-content-link-label {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  width: 40px;
  height: 28px;
  line-height: 28px;
}

.middle-navigator-edit-snippet-content-link-label-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  color: #20b2aa;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
  width: 90%;
  height: 28px;
  line-height: 24px;
  border-radius: 5px;
  border: 2px solid #0f3676;
  background-color: #ebf8ff;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-content-link-label-input:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}
.middle-navigator-edit-snippet-content-link-label-input.url {
  color: #0f3676;
  font-weight: normal;
}

.middle-navigator-edit-snippet-left-addLinkIcon-container {
  width: 33px;
  height: 52px;
  cursor: pointer;
  filter: grayscale(1);
  opacity: 0.6;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-left-addLinkIcon-container:hover {
  filter: grayscale(0);
  opacity: 1;
}
.middle-navigator-edit-snippet-left-addLinkIcon-imgContainer {
  width: 33px;
  height: 33px;
}
.middle-navigator-edit-snippet-left-addLinkIcon-container .description {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #2389b1;
}
.middle-navigator-edit-snippet-content-insert-contactUrl-bar-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.middle-navigator-edit-snippet-content-insert-contactUrl-bar-left {
  display: inline-block;
  height: 47px;
  line-height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #0f3676;
  color: white;
  border-radius: 8px 0 0 8px;
}
.middle-navigator-edit-snippet-content-insert-contactUrl-bar-right {
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
  height: 47px;
  background-color: #ebf8ff;
  width: 70%;
  border: 2px solid #0f3676;
  border-radius: 0 8px 8px 0;
}
.middle-navigator-edit-snippet-right-addLinkIcon-container {
  cursor: pointer;
  width: 33px;
  height: 33px;
  filter: grayscale(1);
  opacity: 0.6;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-right-addLinkIcon-container:hover {
  filter: grayscale(0);
  opacity: 1;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-container {
  margin-right: 10px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-container .upper,
.middle-navigator-edit-snippet-right-addLinkIcon-url-container .leftside {
  display: flex;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-container .leftside {
  width: 565px;
}
.middle-navigator-edit-snippet-right-icon-container {
  position: relative;
  cursor: pointer;
  font-size: 15px;
  border: 2px solid #0f3676;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #0f3676;
  color: white;
  transition: 0.4s;
}

.middle-navigator-edit-snippet-right-icon-container.small {
  font-size: 13px;
  height: 22px;
  width: 22px;
}
.middle-navigator-edit-snippet-right-icon-container:hover {
  background-color: #ffd837;
  color: #0f3676;
  font-size: 15px;
  border: 2px solid #0f3676;
}
.middle-navigator-edit-snippet-right-icon-container .plusIcon {
  position: absolute;
  top: 3px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-icon-container .minusIcon {
  position: absolute;
  top: 3px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-icon-container .plusIcon.small {
  top: 3px;
  left: 4.5px;
}

.middle-navigator-edit-snippet-right-icon-container .trashIcon {
  position: absolute;
  top: 2px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-icon-container .trashIcon.small {
  top: 2px;
  left: 4.5px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-label-container {
  display: flex;
  line-height: 33px;
  font-weight: bold;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-input {
  display: inline-block;
  margin: auto;
  margin-left: 5px;
  width: 60px;
  text-align: center;
  padding-top: 2px;
  height: 24px;
  color: #2389b1;
  font-weight: bold;
  border: 2px solid #0f3676;
  border-radius: 8px;
  background-color: #ebf8ff;
  transition: 0.4s;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-label {
  width: 33px;
  height: 33px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-label-img {
  width: 33px;
  height: 33px;
}
.middle-navigator-edit-snippet-right-addLinkIcon-url-input.url {
  width: 400px;
  margin-left: 5px;
  padding-left: 6px;
  padding-right: 6px;
  color: #0f3676;
  font-weight: normal;
  text-align: left;
}
