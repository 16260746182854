.nav1_nav-zone {
  width: 216px;
}

.nav1_nav-zone-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.nav1_nav-zone-container a:hover {
  background: #c5efff;
  transition: 0.5s;
}

.nav1_nav-zone-container-btnContainer {
  width: 72px;
  height: 90px;
}
.nav1_nav-zone-container-btnContainer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
}

.nav1_nav-zone-container-btnContainer-text {
  text-align: center;
  font-weight: bold;
  color: #0f3676;
  font-size: 0.95rem;
}

/* ----------------------------------- */
#leftNewsContainer .news,
.leftNoticeContainer .news {
  margin: 0 5px 30px;
}

#leftNewsContainer .news td,
.leftNoticeContainer .news td {
  border-bottom: 1px solid #c5efff;
  padding: 2px;
  font-size: 12px;
}

#leftNewsContainer .news .date,
.leftNoticeContainer .news .date {
  width: 42px;
  padding-left: 5px;
  font-weight: bold;
  color: #0f3676;
}
#leftNewsContainer .news .title,
.leftNoticeContainer .news .title {
  width: 164px;
  color: #0f3676;
}

#leftNewsContainer .news .link,
.leftNoticeContainer .news .link {
  color: #2389b1;
  transition: 0.4s;
}

#leftNewsContainer .news .link:hover,
.leftNoticeContainer .news .link:hover {
  color: #dcab1f;
}

#leftNewsContainer .news tr:nth-child(even),
.leftNoticeContainer .news tr:nth-child(even) {
  background-color: #ebf8ff;
}

/* ----------------------------------- */

.nav1_nav-zone-ads {
  display: block;
  margin: auto;
  padding: 8px 0;
}
