.eyeBrow {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 34px;
  background-color: #0f3676;
  z-index: 100;
}

.eyeBrow-content {
  display: flex;
  justify-content: space-between;
  width: 1452px;
}

.eyeBrow-logo {
  padding-top: 1px;
  height: 30px;
  opacity: 0.3;
  transition: 0.3s;
}

.eyeBrow-logo.community {
  padding-left: 30px;
}

.eyeBrow-logo-img {
  height: 30px;
}

.eyeBrow-logo:hover {
  opacity: 1;
}

.eyeBrow-rightZone {
  display: flex;
  justify-content: space-between;
  width: 380px;
}

.eyeBrow-rightZone-price {
  width: 120px;
}
.eyeBrow-rightZone-price.up {
  color: #2ecc71;
}
.eyeBrow-rightZone-price.down {
  color: #e74c3c;
}

.eyeBrow-rightZone-price .text {
  font-family: 'ubuntu', sans-serif;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  transition: 0.3s;
}
.eyeBrow-rightZone-price .text.up {
  color: #2ecc71;
}
.eyeBrow-rightZone-price .text.down {
  color: #e74c3c;
}
.eyeBrow-rightZone-price .text:hover {
  color: #ffd837;
}
.eyeBrow-rightZone-price .text .price {
  width: 110px;
}

.eyeBrow-right-logo {
  display: block;
  margin-top: 6px;
  padding-right: 6px;
  height: 22px;
}

.eyeBrow-rightZone-shortcut-label {
  display: flex;
  line-height: 32px;
  cursor: pointer;
  color: white;
  font-size: 1.05rem;
  font-weight: bold;
  opacity: 0.5;
  transition: 0.3s;
  padding-left: 15px;
}

.eyeBrow-rightZone-shortcut-label:hover,
.eyeBrow-rightZone-shortcut-label.active {
  opacity: 1;
}

.eyeBrow-rightZone-shortcut-text:hover,
.eyeBrow-rightZone-shortcut-text:active {
  color: white;
  opacity: 1;
}
