* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

/* body {
  overflow-x: hidden;
} */

*::before, *::after {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

a {
  background-color: transparent;
}

img {
  border-style: none;
}

table, th, td {
  border-spacing: 0;
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}