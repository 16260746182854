.bottomShortcut {
  display: flex;
  justify-content: space-evenly;
  background-color: #0f3676;
  padding: 20px 0 30px;
  height: 65px;
}
.bottomShortcut .top {
  display: flex;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 20px;
  opacity: 0.5;
  transition: 0.4s;
  line-height: 26px;
}
.bottomShortcut .label {
  position: relative;
  top: 3px;
  width: 60px;
}
.bottomShortcut img {
  padding-top: 5px;
  height: 28px;
}
.bottomShortcut .top:hover {
  opacity: 1;
}
.bottomShortcut .selected {
  display: flex;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 20px;
  opacity: 1;
  transition: 0.4s;
  line-height: 26px;
}

#contact1 {
  display: flex;
  justify-content: space-evenly;
  background-color: #0f3676;
  /* padding: 10px 0 10px; */
  /* height: 60px; */
}

#contact1 img {
  height: 40px;
}
#contact2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 0 10px;
  min-height: 100px;
  background-color: #0f3676;
  border-radius: 0 0 20px 20px;
}
#contact2 a {
  display: block;
  filter: grayscale(50%);
  flex-basis: 45px;
  opacity: 0.5;
  transition: 0.4s;
}
#contact2 a:hover {
  opacity: 1;
  filter: grayscale(0%);
}
