.middle-community-container {
  min-height: 1100px;
}

.middle-community-overall-cover-container {
  display: flex;
  width: 100%;
  min-height: 570px;
  margin-bottom: 8px;
}

.middle-community-cover-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  width: 620px;
}

.middle-community-cover-img-container {
  max-height: 438px;
  overflow: hidden;
}
.middle-community-cover-img-container img {
  width: 610px;
}

.middle-community-side-overall-cover-container {
  width: 190px;
}

.middle-community-cover-title {
  color: #00638a;
  margin: auto;
  margin-top: 0px;
  width: 580px;
  font-size: 30px;
  font-weight: bold;
}
.middle-community-cover-info-container {
  margin: auto;
  font-size: 16px;
  font-weight: bold;
  width: 560px;
  display: flex;
  justify-content: space-between;
  color: #3c7cbd;
}

.middle-community-small-cover-snippet-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 33%;
  padding: 5px;
}

.middle-community-small-cover-snippet-img-container {
  width: 180px;
  max-height: 120px;
  overflow: hidden;
}

.middle-community-small-cover-snippet-img-container img {
  background-color: #2389b1;
  width: 180px;
}

.middle-commnity-small-cover-snippet-title {
  color: #00638a;
  font-size: 13px;
  font-weight: bold;
  margin: auto;
  width: 180px;
}

.middle-community-small-cover-snippet-info-container {
  display: flex;
  justify-content: space-between;
  color: #3c7cbd;
  font-size: 12px;
  width: 170px;
  margin: auto;
}

.middle-community-article-type-container {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  background-color: #0c3a5b;
  height: 34px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  line-height: 34px;
}

.middle-community-article-type-container .type {
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: 0.4s;
}

.middle-community-article-type-container .type:hover {
  background-color: #2389b1;
}

.middle-community-article-type-container .type.active {
  background-color: #2389b1;
}
