.header {
  max-width: 1452px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  height: 72px;
}

.header-logo {
  display: block;
  margin-top: 8px;
  height: 64px;
}

.header-logo img {
  display: block;
  top: 0;
  right: 0;
  height: 64px;
  transition: 0.5s;
}

.header-logo .official {
  opacity: 1;
}
.header-logo .official.active {
  opacity: 1;
}
.header-logo .official.active:hover {
  opacity: 0;
}
.header-logo .official:hover {
  opacity: 1;
}
.header-logo .community {
  position: relative;
  top: -64px;
  opacity: 0;
}
.header-logo .community:hover {
  opacity: 1;
}
.header-logo .community.active {
  opacity: 1;
}
.header-logo .community.active:hover {
  opacity: 0;
}

.version-btn-container {
  margin-top: 20px;
  display: flex;
  height: 40px;
  line-height: 34px;
  font-size: 20px;
  font-weight: bold;
}

.header-official-btn {
  background-color: #aaa;
  color: white;
  width: 90px;
  text-align: center;
  border-radius: 20px 0 0 20px;
  border: 3px solid #0f3676;
  border-right: 0;
  transition: 0.4s;
}
.header-official-btn:hover {
  background-color: #ffd837;
  color: #0f3676;
}
.header-official-btn.active {
  background-color: #0f3676;
  color: white;
}

.header-community-btn {
  background-color: #aaa;
  color: white;
  width: 90px;
  text-align: center;
  border: 3px solid #0f3676;
  border-left: 0;
  border-radius: 0 20px 20px 0;
  transition: 0.4s;
}
.header-community-btn:hover {
  background-color: #99ebff;
  color: #0f3676;
}
.header-community-btn.active {
  background-color: #00638a;
  color: white;
}

.header-communityEditor {
  width: 150px;
  text-align: center;
  border: 3px solid #0f3676;
  border-radius: 20px;
  background-color: #00638a;
  color: white;
}

.header-official-officialEditor {
  cursor: pointer;
  width: 150px;
  text-align: center;
  border: 3px solid #0f3676;
  border-right: 0;
  border-radius: 20px 0 0 20px;
  background-color: #aaa;
  color: white;
  transition: 0.4s;
}

.header-official-communityEditor {
  cursor: pointer;
  width: 150px;
  text-align: center;
  border: 3px solid #0f3676;
  border-left: 0;
  border-radius: 0 20px 20px 0;
  background-color: #aaa;
  color: white;
  transition: 0.4s;
}

.header-official-officialEditor.active {
  background-color: #0f3676;
}
.header-official-communityEditor.active {
  background-color: #00638a;
}

.header .accountPanel {
  display: flex;
  justify-content: space-between;
  width: 280px;
}
.header .accountPanel-left {
  margin-top: 22px;
  display: flex;
  font-family: "Ubuntu", sans-serif, "Microsoft JhengHei", "微軟正黑體";
  font-size: 17px;
  font-weight: bold;
  color: #2389b1;
  line-height: 36px;
}
.header .accountPanel-left-avatar-container {
  margin-right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
}
.header .accountPanel-left-avatar {
  width: 36px;
  height: 36px;
  /* background-color: #0f3676; */
}
.header .accountPanel-left-account {
  color: #00638a;
  transition: 0.4s;
}
.header .accountPanel-left-account:hover {
  color: #2389b1;
}

.header .accountPanel-right {
  display: flex;
}

.header .accountPanel-right-logout-img-container {
  cursor: pointer;
  /* filter: grayscale(40%);
  opacity: 0.9; */
  margin-top: 12px;
  margin-right: 10px;
  width: 42px;
  font-size: 14px;
  font-weight: bold;
  color: #00638a;
  padding: 6px;
  line-height: 14px;
  transition: 0.4s;
}
.header .accountPanel-right-logout-img-container:hover {
  border-radius: 8px;
  background-color: #0f3676;
  color: white;
  /* opacity: 1;
  filter: grayscale(0%);
  color: #2389b1; */
}
