.newsEdit-news {
  border: 2px solid #0f3676;
  border-radius: 0 10px 10px 10px;
  padding: 10px;
}

.newsEdit-news-bar-container {
  display: flex;
  justify-content: space-between;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #0f3676;
  font-weight: bold;
}

.newsEdit-news-bar-left {
  display: flex;
  width: 80%;
}

.newsEdit-news-bar-container.link {
  margin-top: 10px;
}

.newsEdit-news-bar-left-input {
  font-family: 'Ubuntu', sans-serif, 'Microsoft JhengHei', '微軟正黑體';
  font-size: 16px;
  padding-left: 6px;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #0f3676;
  border-width: 2px;
  background-color: #ebf8ff;
  transition: 0.4s;
}

.newsEdit-news-bar-left-input:focus {
  background-color: #c5efff;
  border-color: #2389b1;
}

.newsEdit-news-bar-right {
  cursor: pointer;
  width: 16%;
  border-radius: 8px;
  border: 3px solid #0f3676;
  background-color: #0f3676;
  color: white;
  line-height: 26px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  transition: 0.4s;
}
.newsEdit-news-bar-right:hover {
  background-color: #2389b1;
}

.newsEdit-news-bar-input {
  background-color: #ebf8ff;
  border-width: 0;
  color: #0f3676;
  margin-left: 5px;
  font-size: 16px;
  margin-top: 5px;
  width: 600px;
}

.newsEdit-news-row {
  border-bottom: 1px solid #c5efff;
}
.newsEdit-news-row:nth-child(odd) {
  background-color: white;
}
.newsEdit-news-row:nth-child(even) {
  background-color: #ebf8ff;
}

.newsEdit-news-row-first {
  display: grid;
  grid-template-columns: 60px auto 60px 60px;
  font-size: 14px;
  color: #0f3676;
  padding-top: 4px;
}

/* .newsEdit-news-row-first .messageLabel {
} */

.newsEdit-news-row-first .message {
  background-color: #ebf8ff;
  border: 1px solid #0f3676;
  border-radius: 4px;
  padding-left: 3px;
  width: 520px;
  transition: 0.4s;
  color: #0f3676;
}

.newsEdit-news-row-first .message:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.newsEdit-news-row-first .date {
  color: #0f3676;
  font-size: 14px;
}

.newsEdit-news-row-second {
  display: grid;
  grid-template-columns: auto 60px 60px;
  font-size: 14px;
  color: #0f3676;
  padding-left: 60px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.newsEdit-news-row-second .link {
  background-color: #ebf8ff;
  border: 1px solid #0f3676;
  border-radius: 4px;
  color: #20b2aa;
  padding-left: 3px;
  width: 515px;
  transition: 0.4s;
}

.newsEdit-news-row-second .link:focus {
  border-color: #2389b1;
  background-color: #c5efff;
}

.newsEdit-ps {
  margin-top: 5px;
  color: #2389b1;
  font-size: 14px;
}

.newsEdit-radio {
  cursor: pointer;
}

.newsEdit-news-row-second .saveBtn {
  cursor: pointer;
  color: #2389b1;
  font-weight: bold;
  transition: 0.4s;
}
.newsEdit-news-row-second .saveBtn:hover {
  color: #dcab1f;
}
.newsEdit-news-row-second .deleteBtn {
  cursor: pointer;
  color: rgb(209, 0, 0);
  opacity: 0.7;
  transition: 0.4s;
}
.newsEdit-news-row-second .deleteBtn:hover {
  color: red;
  opacity: 1;
}
