.toast-success-container {
  border-radius: 8px;
  background-color: #00638a;
  /* background-color: #2389b1; */
  font-family: 'Microsoft JhengHei', '微軟正黑體', 'Ubuntu', sans-serif;
  font-size: 17px;
  font-weight: bold;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

/* .toast-success-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34a853;
} */
