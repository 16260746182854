.middleZone-snippet {
  display: flex;
  width: 810px;
  min-height: 101px;
  max-height: 170px;
  border-bottom: 1px solid #c5efff;
}

/* ----------Prview Mode--------------------- */

.preview-snippet {
  display: flex;
  width: 710px;
  min-height: 101px;
  max-height: 170px;
  border-bottom: 1px solid #c5efff;
}

.middleZone-snippet-imgContainer {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.middleZone-snippet-imgContainer .img {
  display: block;
  min-width: 100px;
  max-width: 160px;
  min-height: 80px;
  max-height: 160px;
  margin-top: auto;
  margin-bottom: auto;
}

.middleZone-snippet-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 0;
  margin-left: 15px;
  margin-right: 15px;
}

.middleZone-snippet-content-title {
  display: inline-block;
  /* padding-top: 5px; */
  padding-bottom: 5px;
  font-size: 20px;
  color: #0f3676;
  /* color: #0c3a5b; */
  font-weight: bold;
}

.middleZone-snippet-content-outline {
  font-size: 16px;
  /* color: #0f3676; */
  color: #0c3a5b;
}

.middleZone-snippet-content-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.middleZone-snippet-content-label {
  display: inline-block;
  font-size: 14px;
  color: #3c7cbd;
}

.middleZone-snippet-content-label.userId {
  color: #cc0066;
}

.middleZone-snippet-content-articleType {
  font-size: 14px;
  color: white;
  background-color: #0f3676;
  padding: 0 5px;
  border-radius: 4px;
}
.middleZone-snippet-content-articleType.mainnet {
  background-color: #0f3676;
}
.middleZone-snippet-content-articleType.game {
  background-color: #20b2aa;
}
.middleZone-snippet-content-articleType.market {
  background-color: #05bcff;
}
.middleZone-snippet-content-articleType.dapps {
  background-color: #19dbb6;
}
.middleZone-snippet-content-articleType.community {
  background-color: #008cff;
}
.middleZone-snippet-content-articleType.bp {
  background-color: #0064db;
}
.middleZone-snippet-content-articleType.tutorial {
  background-color: #00d7e2;
}
.middleZone-snippet-content-articleType.comprehensive {
  background-color: #ff3333;
}
.middleZone-snippet-content-articleType.politics {
  background-color: #ff9d0a;
}
.middleZone-snippet-content-articleType.economy {
  background-color: #ddbc00;
}
.middleZone-snippet-content-articleType.industry {
  background-color: #3599c7;
}
.middleZone-snippet-content-articleType.archiculture {
  background-color: #0bbe0b;
}
.middleZone-snippet-content-articleType.commerical {
  background-color: #49c1fd;
}
.middleZone-snippet-content-articleType.technology {
  background-color: #1ac59a;
}
.middleZone-snippet-content-articleType.lifestyle {
  background-color: #e2236c;
}
.middleZone-snippet-content-articleType.leisure {
  background-color: #1bc4ca;
}
