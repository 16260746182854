.loveIcon {
  cursor: pointer;
  background: url("./icons/love_color.svg");
  background-repeat: no-repeat;
  height: 34px;
  width: 34px;
  display: block;
}

.loveIcon.mono {
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.4s;
}
.loveIcon.mono:hover {
  filter: sepia(100%);
  opacity: 1;
}

/* .edit-icon {
  display: block;
  cursor: pointer;
  background: url("./icons/pencil.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: right 2px bottom 2px;
  height: 24px;
  width: 24px;
  fill: #0f3676;
  color: #0f3676;
  border-radius: 50%;
  border: 2px solid #0f3676;
  transition: 0.4s;
}

.edit-icon:hover {
  background-color: #0f3676;
  color: #ffd837;
} */
